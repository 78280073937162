<template>
  <dv-border-box-6>
    <div class="hd">未来交通</div>
    <div id="access" style="height: auto; flex: 1; width:100%"></div>
  </dv-border-box-6>
</template>

<script>
import * as echarts from 'echarts'
export default {
  name: 'LeftChart3',
  data () {
    return {
    }
  },
  methods: {
    load() {
      this.drawAccess()
    },
    drawAccess(){
      var myChart = echarts.init(document.getElementById("access"), 'dark');
      var option
      option = {
          tooltip: {
              trigger: 'axis',
              axisPointer: {
                  type: 'cross',
                  label: {
                      backgroundColor: '#283b56'
                  }
              }
          },
          legend: {
              data:['进入', '出去']
          },
          dataZoom: {
              show: false,
              start: 0,
              end: 100
          },
          color: ['#00baff', '#3de7c9', '#fff', '#ffc530', '#469f4b'],
          grid: {
            bottom: 30,
            left: 30,
            right: 30,
          },
          xAxis: [
              {
                  type: 'category',
                  boundaryGap: true,
                  data: (function (){
                      var now = new Date();
                      var res = [];
                      var len = 10;
                      while (len--) {
                          res.unshift(now.toLocaleTimeString().replace(/^\D*/,''));
                          now = new Date(now - 2000);
                      }
                      return res;
                  })()
              },
              {
                  type: 'category',
                  boundaryGap: true,
                  data: (function (){
                      var res = [];
                      var len = 10;
                      while (len--) {
                          res.push(10 - len - 1);
                      }
                      return res;
                  })()
              }
          ],
          yAxis: [
              {
                  type: 'value',
                  scale: true,
                  name: '进入',
                  max: 20,
                  min: 0,
                  boundaryGap: [0.2, 0.2]
              },
              {
                  type: 'value',
                  scale: true,
                  name: '出去',
                  max: 20,
                  min: 0,
                  boundaryGap: [0.2, 0.2]
              }
          ],
          series: [
              {
                  name: '进入',
                  type: 'bar',
                  xAxisIndex: 1,
                  yAxisIndex: 1,
                  data: (function (){
                      var res = [];
                      var len = 10;
                      while (len--) {
                          res.push(Math.round(Math.random() * 10));
                      }
                      return res;
                  })()
              },
              {
                  name: '出去',
                  type: 'line',
                  data: (function (){
                      var res = [];
                      var len = 0;
                      while (len < 10) {
                          res.push((Math.random()*10 + 5).toFixed(1) - 0);
                          len++;
                      }
                      return res;
                  })()
              }
          ]
      };

      let count = 11;
      setInterval(function (){
          var axisData = (new Date()).toLocaleTimeString().replace(/^\D*/, '');

          var data0 = option.series[0].data;
          var data1 = option.series[1].data;
          data0.shift();
          data0.push(Math.round(Math.random() * 10));
          data1.shift();
          data1.push((Math.random() * 10 + 5).toFixed(1) - 0);

          option.xAxis[0].data.shift();
          option.xAxis[0].data.push(axisData);
          option.xAxis[1].data.shift();
          option.xAxis[1].data.push(count++);

          myChart.setOption(option);
      }, 2100);
      // myChart.setOption(option);
      option && myChart.setOption(option);
    }
  },
  mounted () {
    this.load()
  }
}
</script>

<style lang="less" scoped>
.hd {
  font-size: 24px;
  font-weight: 600;
}
</style>