
<template>
  <dv-border-box-8>
    <div class="hd">未来物业</div>
    <dv-scroll-board :config="config" />
    <dv-decoration-2 style="height:10px;" />
  </dv-border-box-8>
</template>

<script>
export default {
  name: 'Property',
  data () {
    return {
      config: {
        header: ['日期', '时间', '问题', '处理结果'],
        data: [
          ['2021-01-01', '8:00', '问题A', '已处理'],
          ['2021-01-02', '9:00', '问题B', '已处理'],
          ['2021-01-03', '10:00', '问题C', '已处理'],
          ['2021-01-04', '11:00', '问题D', '已处理'],
          ['2021-01-05', '12:00', '问题E', '已处理'],
          ['2021-01-06', '8:00', '问题F', '已处理'],
          ['2021-01-07', '9:00', '问题G', '已处理'],
          ['2021-01-08', '10:00', '问题H', '已处理'],
          ['2021-01-09', '11:00', '问题I', '已处理'],
          ['2021-01-10', '12:00', '问题J', '已处理'],
        ],
        index: true,
        columnWidth: [50, 100, 60, 100, 80],
        align: ['center'],
        rowNum: 7,
        headerBGC: '#1981f6',
        headerHeight: 32,
        oddRowBGC: 'rgba(0, 44, 81, 0.8)',
        evenRowBGC: 'rgba(10, 29, 50, 0.8)'
      }
    }
  }
}
</script>

<style lang="less" scoped>
.hd {
  font-size: 24px;
  font-weight: 600;
}
</style>