<template>
  <div style="display: flex; flex-direction: column;">
    <div class="hd">未来低碳</div>
    <div id="low-carbon" style="flex: 1; width:100%"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import ecStat from 'echarts-stat'
/* eslint-disable */
export default {
  data () {
    return {
      config: {
        unit: '件'
      }
    }
  },
  methods: {
    load() {
      echarts.registerTransform(ecStat.transform.regression);
      var myChart = echarts.init(document.getElementById('low-carbon'), 'dark');
      var option = {
        dataset: [{
          source: [
              [1, 80.4],
              [2, 128.7],
              [3, 172.5],
              [4, 140.0],
              [5, 130.4],
              [6, 160.2],
              [7, 180.5],
              [8, 115.3],
              [9, 160.2],
              [10, 130.9],
              [11, 40.8],
              [12, 60.1],
              [13, 190.4],
              [14, 185.4],
              [15, 115.1],
              [16, 135.6],
              [17, 175.6],
              [18, 237.7]
            ]
          }, {
            transform: {
              type: 'ecStat:regression',
              config: {
                method: 'exponential',
                // 'end' by default
                // formulaOn: 'start'
              }
          }
        }],
        grid: {
          top: 10,
          right: 20,
          bottom: 20,
          left: 30,
        },
        // title: {
        //   text: '文旅收入成长(18月)',
        //   left: 'center',
        // },
        tooltip: {
            trigger: 'axis',
            axisPointer: {
                type: 'cross'
            }
        },
        xAxis: {
            splitLine: {
                lineStyle: {
                    type: 'dashed'
                }
            }
        },
        yAxis: {
            splitLine: {
                lineStyle: {
                    type: 'dashed'
                }
            }
        },
        series: [{
            type: 'line',
            datasetIndex: 0
        }]
      };
      myChart.setOption(option);
    },
  },
  mounted() {
    this.load()
  }
}
</script>

<style lang="less" scoped>
.hd {
  font-size: 24px;
  font-weight: 600;
}
</style>
