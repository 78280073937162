<template>
  <div style="display: flex; flex-direction: column;">
    <div class="hd">未来服务</div>
    <div id="servcie" style="flex: 1; width:100%"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import ecStat from 'echarts-stat'
/* eslint-disable */
export default {
  data () {
    return {}
  },
  methods: {
    load() {
      echarts.registerTransform(ecStat.transform.regression);
      var myChart = echarts.init(document.getElementById('servcie'), 'dark');
      var option
      option = {
          tooltip: {
              trigger: 'item'
          },
          grid: {
            right: 100,
          },
          // legend: {
          //     orient: 'vertical',
          //     left: 'left',
          // },
          series: [
              {
                  name: '访问来源',
                  type: 'pie',
                  radius: '50%',
                  data: [
                      {value: 40, name: '非常满意'},
                      {value: 30, name: '满意'},
                      {value: 20, name: '基本满意'},
                      {value: 10, name: '不满意'},
                  ],
                  emphasis: {
                      itemStyle: {
                          shadowBlur: 10,
                          shadowOffsetX: 0,
                          shadowColor: 'rgba(0, 0, 0, 0.5)'
                      }
                  }
              }
          ]
      };
      // myChart.setOption(option);
      option && myChart.setOption(option);
    },
  },
  mounted() {
    this.load()
  }
}
</script>
<style lang="less" scoped>
.hd {
  font-size: 24px;
  font-weight: 600;
}
</style>
