<template>
<!-- eslint-disable -->
  <div style="width: 100%; height: 100%; overflow: hidden;" v-resize:debounce="onResize">
    <div
      style="width: 1920px; height: 1080px; transform-origin: left top; display: flex; flex-direction: column;"
      :style="{transform: scaleStyle}">
      <div style="display: flex; flex-direction: row; flex-grow:1; flex:1" class="body">
        <!-- col-1 -->
        <div class="" style="display: flex; flex-direction: column; flex-grow:1; flex:1;">
          <div style="display: flex; flex-direction: row; flex-grow:1; flex:1; margin:5px; height: 200px">
            <div class="box" style="display: flex; flex-direction: column; flex-grow:1; flex:1; margin:5px">
              <div class="hd"><h4>业主满意度</h4></div>
              <div class="bd">
                <div style="padding: 20px 40px;">
                  <svg width="520px" height="172px" viewBox="0 0 508 209" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                      <g id="主页" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                          <g id="Desktop-HD" transform="translate(-1058.000000, -138.000000)">
                              <g id="业主满意度" transform="translate(1012.000000, 90.000000)">
                                  <g id="编组-4" transform="translate(46.793692, 43.000000)">
                                      <polyline id="路径-9" stroke="#979797" points="0 27.6666667 0 213 307.206308 213"></polyline>
                                      <polygon id="路径-10" stroke="#979797" fill="#25C6FC" points="0 213 0 131 70.8289493 111 139.720004 131 246.706308 85 290.206308 70 290.206308 213"></polygon>
                                      <text id="业主满意度调查" font-family="PingFangSC-Regular, PingFang SC" font-size="24" font-weight="normal" fill="#FFFFFF">
                                          <tspan x="52.2063077" y="25">业主满意度调查</tspan>
                                      </text>
                                  </g>
                              </g>
                          </g>
                      </g>
                  </svg>
                </div>

                <div style="padding: 20px 40px;">
                  <svg width="520px" height="190px" viewBox="0 0 508 235" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                      <g id="主页" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                          <g id="Desktop-HD" transform="translate(-1058.000000, -421.000000)">
                              <g id="业主满意度" transform="translate(1012.000000, 90.000000)">
                                  <g id="编组-5" transform="translate(46.793692, 326.000000)">
                                      <text id="业主投诉统计" font-family="PingFangSC-Regular, PingFang SC" font-size="24" font-weight="normal" fill="#FFFFFF">
                                          <tspan x="52.2063077" y="25">业主投诉统计</tspan>
                                      </text>
                                      <polyline id="路径-7" stroke="#979797" points="0 34 0 239 307.206308 239"></polyline>
                                      <rect id="矩形" stroke="#979797" fill="#7ED321" x="0.206307743" y="87" width="100" height="23" rx="10"></rect>
                                      <rect id="矩形备份" stroke="#979797" fill="#25C6FC" x="0.206307743" y="130" width="150" height="23" rx="10"></rect>
                                      <rect id="矩形备份-2" stroke="#979797" fill="#50E3C2" x="0.206307743" y="173" width="220" height="23" rx="10"></rect>
                                      <rect id="矩形备份-3" stroke="#979797" fill="#F8E71C" x="0.206307743" y="216" width="180" height="23" rx="10"></rect>
                                      <text id="卫生问题" font-family="PingFangSC-Regular, PingFang SC" font-size="14" font-weight="normal" fill="#FFFFFF">
                                          <tspan x="122.206308" y="101">卫生问题</tspan>
                                      </text>
                                      <text id="服务问题" font-family="PingFangSC-Regular, PingFang SC" font-size="14" font-weight="normal" fill="#FFFFFF">
                                          <tspan x="178.206308" y="145">服务问题</tspan>
                                      </text>
                                      <text id="设备问题" font-family="PingFangSC-Regular, PingFang SC" font-size="14" font-weight="normal" fill="#FFFFFF">
                                          <tspan x="234.206308" y="188">设备问题</tspan>
                                      </text>
                                      <text id="其他" font-family="PingFangSC-Regular, PingFang SC" font-size="14" font-weight="normal" fill="#FFFFFF">
                                          <tspan x="192.206308" y="228">其他</tspan>
                                      </text>
                                  </g>
                              </g>
                          </g>
                      </g>
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <div style="display: flex; flex-direction: row; flex-grow:1; flex:1; margin:5px; height: 200px">
            <div class="box" style="display: flex; flex-direction: column; flex-grow:1; flex:1; margin:5px">
              <div class="hd"><h4>管家 - 业主打分</h4></div>
              <div class="bd">
                <ul style="list-style: none;">
                  <li v-for="item in guanJiaList" style="display: flex; flex-direction: row;">
                    <div><img :src="item.head" width="60" height="60" style="object-fit: cover;"></img></div>
                    <div style="flex: 1; padding: 0 10px;">
                      {{item.name}}
                      <el-rate
                        v-model="item.value"
                        disabled
                        show-score
                        text-color="#ff9900"
                        score-template="{value}">
                      </el-rate>
                    </div>
                  </li>
                  <!-- <li>李斯凯 <span class="text-primary">1200</span></li>
                  <li>王武 <span class="text-primary">800</span></li>
                  <li>赵柳 <span class="text-primary">700</span></li>
                  <li>孙奇 <span class="text-primary">500</span></li>
                  <li>马玖灵 <span class="text-primary">500</span></li>
                  <li>杨诗诗 <span class="text-primary">400</span></li> -->
                </ul>
              </div>
            </div>
          </div>
        </div>

        <!-- col-2 -->
        <div class="" style="display: flex; flex-direction: column; flex-grow:1; flex:1;">
          <div style="display: flex; flex-direction: row; flex-grow:2; flex:2; margin:5px; height: 200px">
            <div class="box" style="display: flex; flex-direction: column; flex-grow:1; flex:1; margin:5px">
              <div class="hd"><h4>一键求助</h4></div>
              <div class="bd" style="display: flex; flex-direction: column;">
                <div style="flex: 1;">
                  <ul>
                    <li style="padding: 10px;" class="list-text">用户请求协助 <span style="display: inline-block;" class="text-primary">10分钟前</span> <span class="text-primary">A区5号楼</span></li>
                    <li style="padding: 10px;" class="list-text">用户请求协助 <span style="display: inline-block;" class="text-primary">30分钟前</span> <span class="text-primary">A区3号楼</span></li>
                    <li style="padding: 10px;" class="list-text">用户请求协助 <span style="display: inline-block;" class="text-primary">1小时前</span> <span class="text-primary">B区1号楼</span></li>
                  </ul>
                </div>
                <div style="height: 400px;">
                  <chart
                    :options="mapChartOptions"
                    ref="mapChart"
                    style="width: 100%; height: 100%; border-radius: 50px;"
                    auto-resize
                  />
                </div>
              </div>
            </div>
          </div>
          <div style="display: flex; flex-direction: row; flex-grow:1; flex:1; margin:5px; height: 200px">
            <div class="box" style="display: flex; flex-direction: column; flex-grow:1; flex:1; margin:5px">
              <div class="hd"><h4>维修请求</h4></div>
              <div class="bd">
                <el-row>
                  <el-col :span="8">
                    <div class="text-center label">请求</div>
                    <div class="text-center text-primary value">100</div>
                  </el-col>
                  <el-col :span="8">
                    <div class="text-center label">完结</div>
                    <div class="text-center text-success value">36</div>
                  </el-col>
                  <el-col :span="8">
                    <div class="text-center label">预期</div>
                    <div class="text-center text-danger value">0</div>
                  </el-col>
                </el-row>
                <ul>
                  <li class="list-text">A幢3楼 <span style="display: inline-block;" class="text-primary">走道灯损坏</span></li>
                  <li class="list-text">B幢5楼 <span style="display: inline-block;" class="text-primary">走道灯损坏</span></li>
                  <li class="list-text">C幢2楼 <span style="display: inline-block;" class="text-primary">智能电表损坏</span></li>
                  <li class="list-text">C幢3楼 <span style="display: inline-block;" class="text-primary">智能烟感需要维护</span></li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <!-- col-3 -->
        <div class="" style="display: flex; flex-direction: column; flex-grow:1; flex:1;">
          <div style="display: flex; flex-direction: row; flex-grow:1; flex:1; margin:5px; height: 200px">
            <div class="box" style="display: flex; flex-direction: column; flex-grow:1; flex:1; margin:5px">
              <div class="hd"><h4>智慧用电</h4></div>
              <div class="bd">
                <div id="electric" style="width: 100%; height: 100%;"></div>
              </div>
            </div>
          </div>
          <div style="display: flex; flex-direction: row; flex-grow:1; flex:1; margin:5px; height: 200px">
            <div class="alarm-list box" style="display: flex; flex-direction: column; flex-grow:1; flex:1; margin:5px">
              <div class="hd"><h4>用电报警</h4></div>
              <div class="bd">
                <ul>
                  <li v-for="item in powerAlarmList" class="clearfix">
                      <span>{{ item.name }}</span>
                      <span class="text-info" style="float:right;">{{
                        item.typeDesc
                      }}</span
                      ><br />
                      <span style="color: #ffd976;">{{ item.codeDesc }}</span>
                      <span class="text-info" v-if="item.remark"
                        >({{ item.remark }})</span
                      >
                      <span class="text-info" style="float:right;">{{
                        item.createTime | timeDesc
                      }}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div style="display: flex; flex-direction: row; flex-grow:1; flex:1; margin:5px; height: 200px">
            <div class="box" style="display: flex; flex-direction: column; flex-grow:1; flex:1; margin:5px">
              <div class="hd"><h4>智慧用水</h4></div>
              <div class="bd">
                <div id="water" style="width: 100%; height: 100%;"></div>
              </div>
            </div>
          </div>
          <div style="display: flex; flex-direction: row; flex-grow:1; flex:1; margin:5px; height: 200px">
            <div class="box" style="display: flex; flex-direction: column; flex-grow:1; flex:1; margin:5px">
              <div class="hd"><h4>用水报警</h4></div>
              <div class="bd">
                <ul>
                  <li v-for="item in waterAlarmList" class="clearfix">
                      <span>{{ item.name }}</span>
                      <span class="text-info" style="float:right;">{{
                        item.typeDesc
                      }}</span
                      ><br />
                      <span style="color: #ffd976;">{{ item.codeDesc }}</span>
                      <span class="text-info" v-if="item.remark"
                        >({{ item.remark }})</span
                      >
                      <span class="text-info" style="float:right;">{{
                        item.createTime | timeDesc
                      }}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import resize from 'vue-resize-directive'
import * as echarts from 'echarts'

export default {
  directives: {
    resize
  },
  data () {
    // 地图配置
    let mapChartOptions = {
      // title: {
      //   text: '智慧用电、消防和生产安全 监控云平台',
      //   top: 10,
      //   left: 'center',
      //   padding: [20, 100],
      //   backgroundColor: 'rgba(0, 0, 0, 0.7)',
      //   textStyle: {
      //     fontSize: 32,
      //     color: '$--color-primary',
      //     fontWeight: 800
      //   }
      // },
      tooltip: {
        trigger: 'item',
        formatter: function (val) {
          return val.value.name
        }
      },
      dataset: {
        source: [{longitude: 120.5, latitude: 31, status: 20}]
      },
      bmap: {
        center: [120, 31],
        zoom: 7,
        roam: true,
        mapStyle: {
          // style: 'dark',
          styleJson: [
            {
              'featureType': 'all',
              'elementType': 'labels.text.fill',
              'stylers': {
                'color': '#ffffffff'
              }
            },
            {
              'featureType': 'all',
              'elementType': 'labels.text.stroke',
              'stylers': {
                'color': '#000000',
                'weight': '0.1'
              }
            },
            {
              'featureType': 'land',
              'elementType': 'geometry',
              'stylers': {
                'color': '#0a1429'
              }
            },
            {
              'featureType': 'building',
              'elementType': 'geometry',
              'stylers': {
                'color': '#2b2b2b'
              }
            },
            {
              'featureType': 'highway',
              'elementType': 'all',
              'stylers': {
                'lightness': -42,
                'saturation': -91
              }
            },
            {
              'featureType': 'arterial',
              'elementType': 'geometry',
              'stylers': {
                'lightness': -77,
                'saturation': -94
              }
            },
            {
              'featureType': 'green',
              'elementType': 'geometry',
              'stylers': {
                'color': '#1b1b1b'
              }
            },
            {
              'featureType': 'water',
              'elementType': 'geometry',
              'stylers': {
                'color': '#0d1a36'
              }
            },
            {
              'featureType': 'water',
              'elementType': 'labels',
              'stylers': {
                'visibility': 'off'
              }
            },
            {
              'featureType': 'water',
              'elementType': 'labels.text.fill',
              'stylers': {
                'color': '#ffffffff'
              }
            },
            {
              'featureType': 'continent',
              'elementType': 'labels',
              'stylers': {
                'visibility': 'off'
              }
            },
            {
              'featureType': 'country',
              'elementType': 'labels',
              'stylers': {
                'visibility': 'off'
              }
            },
            {
              'featureType': 'country',
              'elementType': 'labels.text.fill',
              'stylers': {
                'color': '#ffffffff'
              }
            },
            {
              'featureType': 'country',
              'elementType': 'labels.text.stroke',
              'stylers': {
                'color': '#ffffff00',
                'weight': 0
              }
            },
            {
              'featureType': 'city',
              'elementType': 'labels.text.fill',
              'stylers': {
                'color': '#ffffffff'
              }
            },
            {
              'featureType': 'city',
              'elementType': 'labels.text.stroke',
              'stylers': {
                'color': '#ffffff00',
                'weight': 0
              }
            },
            {
              'featureType': 'district',
              'elementType': 'labels.text.fill',
              'stylers': {
                'color': '#ffffffff'
              }
            },
            {
              'featureType': 'district',
              'elementType': 'labels.text.stroke',
              'stylers': {
                'color': '#ffffff00',
                'weight': 0
              }
            },
            {
              'featureType': 'town',
              'elementType': 'labels.text.fill',
              'stylers': {
                'color': '#ffffffff'
              }
            },
            {
              'featureType': 'town',
              'elementType': 'labels.text.stroke',
              'stylers': {
                'color': '#ffffff00',
                'weight': 0
              }
            },
            {
              'featureType': 'village',
              'elementType': 'labels.text.fill',
              'stylers': {
                'color': '#ffffffff'
              }
            },
            {
              'featureType': 'village',
              'elementType': 'labels.text.stroke',
              'stylers': {
                'color': '#ffffff00',
                'weight': 0
              }
            }
          ]
        }
      },
      series: {
        id: 'powerMapScatter',
        name: '电表',
        type: 'scatter',
        coordinateSystem: 'bmap',
        encode: {
          lng: 'longitude',
          lat: 'latitude'
        },
        symbolSize: function (value, params) {
          if (!params.value) return 10
          if (params.value.status === 0) return 10
          else if (params.value.status === 10) return 15
          else if (params.value.status === 20) return 15
          return 10
        },
        label: {
          normal: {
            formatter: '{@name}',
            position: 'right',
            color: '#003300',
            show: false
          },
          emphasis: {
            show: false
          }
        },
        itemStyle: {
          normal: {
            color: function (val) {
              if (val.value.status === 0) return '#94d573'
              else if (val.value.status === 10) return '#ffd25d'
              else if (val.value.status === 20) return '#ff6b50'
              return '#CCCCCC'
            }
          }
        }
      }
    }
    return {
      scaleStyle: '',
      guanJiaList: [
        { name: '白羊', head: 'https://res.hudsonpower.cn/static/head_01.jpg', value: 5 },
        { name: '红苹果', head: 'https://res.hudsonpower.cn/static/head_02.jpg', value: 4.5 },
        { name: '青柠', head: 'https://res.hudsonpower.cn/static/head_03.jpg', value: 4 },
        { name: '青芒果', head: 'https://res.hudsonpower.cn/static/head_04.jpg', value: 3.5 },
        { name: '黑凤梨', head: 'https://res.hudsonpower.cn/static/head_05.jpg', value: 3 },
        { name: '红椒', head: 'https://res.hudsonpower.cn/static/head_06.jpg', value: 3 }
      ],
      mapChartOptions: mapChartOptions,
      powerAlarmList: [
        {name: 'A区1-3电表', codeDesc: '过温报警', remark: '65度', typeDesc: '智能电表', createTime: new Date().getTime() - 200 * 1000},
        {name: 'B区1-3电表', codeDesc: '电流过大报警', remark: '38A', typeDesc: '智能电表', createTime: new Date().getTime() - 700 * 1000},
        {name: 'C区2-3电表', codeDesc: '电压过低报警', remark: '199V', typeDesc: '智能电表', createTime: new Date().getTime() - 8000 * 1000}
      ],
      waterAlarmList: [
        {name: 'A区1-3水表', codeDesc: '过温报警', remark: '45度', typeDesc: '智能水表', createTime: new Date().getTime() - 400 * 1000},
        {name: 'B区1-3消防栓', codeDesc: '水压过低报警', remark: '0.05MPa', typeDesc: '智能消防栓', createTime: new Date().getTime() - 1300 * 1000}
      ]
    }
  },
  methods: {
    onResize (el) {
      let scaleX = el.clientWidth / 1920
      let scaleY = el.clientHeight / 1080
      scaleX = scaleX < 0.5 ? 0.5 : scaleX
      scaleY = scaleY < 0.5 ? 0.5 : scaleY
      scaleX = scaleX > 2 ? 2 : scaleX
      scaleY = scaleY > 2 ? 2 : scaleY

      // 比例调节
      if (scaleY / scaleX > 1.25) scaleY = scaleX * 1.25

      this.scaleStyle = `scale(${scaleX}, ${scaleY})`
    },
    drawElectric (id) {
      var myChart = echarts.init(document.getElementById(id))
      var option = {
        backgroundColor: '',
        textStyle: {
          color: '#fff'
        },
        color: ['#25C6FC', '#67c23a', '#FFBD10', '#FF401D'],
        title: {
          text: '瞬时用电',
          show: false,
          textStyle: {
            color: '#fff'
          }
        },
        legend: {
          data: ['瞬时用电'],
          textStyle: {
            color: '#fff'
          }
        },
        dataZoom: {
          show: false,
          start: 0,
          end: 100
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: true,
            data: (function () {
              var now = new Date()
              var res = []
              var len = 10
              while (len--) {
                res.unshift(now.toLocaleTimeString().replace(/^\D*/, ''))
                now = new Date(now - 3000)
              }
              return res
            })()
          }
        ],
        yAxis: [
          {
            type: 'value',
            scale: true,
            name: '用电量(度)',
            min: 0,
            boundaryGap: [0.2, 0.2]
          }
        ],
        series: [
          {
            name: '瞬时用电',
            type: 'line',
            xAxisIndex: 0,
            yAxisIndex: 0,
            data: (function () {
              var res = []
              var len = 10
              while (len--) {
                res.push(Math.round(Math.random() * 500) + 1000)
              }
              return res
            })()
          }
        ]
      }

      setInterval(function () {
        var axisData = (new Date()).toLocaleTimeString().replace(/^\D*/, '')
        var data0 = option.series[0].data
        data0.shift()
        data0.push(Math.round(Math.random() * 500) + 1000)
        option.xAxis[0].data.shift()
        option.xAxis[0].data.push(axisData)
        myChart.setOption(option)
      }, 3000)
    },
    drawWater (id) {
      var myChart = echarts.init(document.getElementById(id))
      var option = {
        backgroundColor: '',
        textStyle: {
          color: '#fff'
        },
        color: ['#25C6FC', '#67c23a', '#FFBD10', '#FF401D'],
        title: {
          text: '瞬时用水',
          show: false,
          textStyle: {
            color: '#fff'
          }
        },
        legend: {
          data: ['瞬时用水'],
          textStyle: {
            color: '#fff'
          }
        },
        dataZoom: {
          show: false,
          start: 0,
          end: 100
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: true,
            data: (function () {
              var now = new Date()
              var res = []
              var len = 10
              while (len--) {
                res.unshift(now.toLocaleTimeString().replace(/^\D*/, ''))
                now = new Date(now - 3000)
              }
              return res
            })()
          }
        ],
        yAxis: [
          {
            type: 'value',
            scale: true,
            name: '用水量(顿)',
            min: 0,
            boundaryGap: [0.2, 0.2]
          }
        ],
        series: [
          {
            name: '瞬时用水',
            type: 'line',
            data: (function () {
              var res = []
              var len = 0
              while (len < 10) {
                res.push(Math.random() * 20 + 20)
                len++
              }
              return res
            })()
          }
        ]
      }

      setInterval(function () {
        var axisData = (new Date()).toLocaleTimeString().replace(/^\D*/, '')
        var data0 = option.series[0].data
        data0.shift()
        data0.push(Math.round(Math.random() * 20) + 20)
        option.xAxis[0].data.shift()
        option.xAxis[0].data.push(axisData)
        myChart.setOption(option)
      }, 3000)
    }
  },
  mounted () {
    this.drawElectric('electric')
    this.drawWater('water')
  }
}
</script>


<style lang='scss' scoped>
@import "src/style/variables";
.list-text {
  color: white;
}
.body {
  flex: 1;
  background-color: #0c1e5a;
  // background-color: #0c3d73;
  box-sizing: border-box;
  border: solid 1px $--color-primary;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  margin: 10px;
  box-shadow: 0 0 10px 0 $--color-primary inset;
  display: flex;
  flex-direction: column;
  &.alarm {
    animation-name: alarmframes;
    animation-duration: 2s;
    animation-timing-function: ease;
    animation-iteration-count: infinite;
  }
}
.box {
  // background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  border: solid 2px #25c6fc;
  box-shadow: 0 0 20px 0 #ffffff99 inset;
  padding: 10px;
  display: flex;
  flex-direction: column;
  .hd {
    // background-color: rgba(0, 0, 0, .3);
    color: $--color-primary;
    height: 20px;
    padding: 5px 0;
    overflow: hidden;
    a {
      color: $--color-primary;
    }
    h4 {
      border-left: solid 5px $--color-primary;
      font-size: 16px;
      margin: 0;
      margin-left: 5px;
      padding: 0 10px;
      line-height: 20px;
      // text-align: center;
    }
  }
  .bd {
    // background-color: rgba(0, 0, 0, .5);
    flex: 1;
    height: 100px;
    //line-height: 30px;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .bd.dumy {
    background: rgba(255, 255, 255, 0.1);
  }
}

.el-table.table-dark {
  background-color: rgba(255, 255, 255, 0.1);
  /deep/ tr, /deep/ th {
    background-color: transparent;
  }
  /deep/ .el-table__row {
    // background-color: rgba(255, 255, 255, .2);
    color: #fff;
  }
  
  /deep/ .el-table__row.hover-row td {
    color: #fff;
    background-color: rgba(255, 255, 255, .2);
  }
  /deep/ .el-table__body tr:hover > td {
    color: #fff;
    background-color: rgba(255, 255, 255, .2);
  }
  /deep/ .el-table__header th, .el-table__header tr {
    // background-color: rgba(255, 255, 255, .5);
    // color: #666;
  }
}

::-webkit-scrollbar {
  background-color: rgba(255, 255, 255, 0.1);
  width: 8px;
  height: 8px;
  border-radius: 2px;
}
::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 0.3);
}


.alarm-list {
  color: #fff;
  ul {
    padding: 5px 20px;
    list-style: none;
    li {
      font-size: 1em;
      padding: 10px 0;
      a {
        color: #fff;
      }
      border-bottom: dashed 1px #999;
    }
  }
}

</style>