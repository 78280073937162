<template>
  <div style="display: flex; flex-direction: column;">
    <div class="hd">垃圾回收</div>
    <div id="waste" style="flex: 1; width:100%"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import ecStat from 'echarts-stat'

/* eslint-disable */
export default {
  data () {
    return {
    }
  },
  methods: {
    load() {
      echarts.registerTransform(ecStat.transform.regression);
      var myChart = echarts.init(document.getElementById('waste'), 'dark');
      var option = {
          // title: {
          //   text: '各行业税收贡献',
          //   left: 'center',
          // },
          tooltip: {
              trigger: 'axis',
              axisPointer: {            // Use axis to trigger tooltip
                  type: 'shadow'        // 'shadow' as default; can also be 'line' or 'shadow'
              }
          },
          legend: {
            top: 25,
            data: ['厨余垃圾', '可回收废品', '有害垃圾', '其他']
          },
          grid: {
            top: 50,
            left: '3%',
            right: '4%',
            bottom: '3%',
            containLabel: true
          },
          xAxis: {
              type: 'value'
          },
          yAxis: {
              type: 'category',
              data: ['2020-10', '2020-11', '2020-12', '2021-01', '2021-02', '2021-03']
          },
          series: [
              {
                  name: '厨余垃圾',
                  type: 'bar',
                  stack: 'total',
                  label: {
                      show: false
                  },
                  emphasis: {
                      focus: 'series'
                  },
                  data: [320, 302, 301, 334, 390, 320]
              },
              {
                  name: '可回收废品',
                  type: 'bar',
                  stack: 'total',
                  label: {
                      show: false
                  },
                  emphasis: {
                      focus: 'series'
                  },
                  data: [120, 132, 101, 134, 90, 210]
              },
              {
                  name: '有害垃圾',
                  type: 'bar',
                  stack: 'total',
                  label: {
                      show: false
                  },
                  emphasis: {
                      focus: 'series'
                  },
                  data: [220, 182, 191, 234, 290, 330]
              },
              {
                  name: '其他',
                  type: 'bar',
                  stack: 'total',
                  label: {
                      show: false
                  },
                  emphasis: {
                      focus: 'series'
                  },
                  data: [150, 212, 201, 154, 190, 410]
              }
          ]
      };

      myChart.setOption(option);
    },
  },
  mounted () {
    this.load()
  }
}
</script>

<style lang="less" scoped>
.hd {
  font-size: 24px;
  font-weight: 600;
}
</style>
