<template>
  <div class="main">
    <div class="login-container">
      <div class="bg"></div>
      <el-form ref="AccountFrom" :model="account" :rules="rules" label-position="left" label-width="0px" class="">
        <h3 class="title">未来社区平台</h3> <br/>
        <el-form-item prop="mobile">
          <el-input type="text" v-model="account.mobile" auto-complete="off" placeholder="账号：手机号"></el-input>
        </el-form-item>
        <el-form-item prop="verifyCode">
          <el-row>
            <el-col :span="15">
              <el-input type="password" v-model="account.verifyCode" auto-complete="off" placeholder="短信验证码" style="width: 80%;"></el-input>
            </el-col>
            <el-col :span="9">
              <el-button type="success" style="width:100%;" v-if="!verifyCodeSent" @click="sendVerifyCode">发送验证码</el-button>
              <el-button type="success" style="width:100%;" v-else :disabled="true">已发送（{{countDown}}'）</el-button>
            </el-col>
          </el-row>
        </el-form-item>
        <!-- <el-checkbox v-model="checked" checked class="remember">记住密码</el-checkbox> -->
        <el-form-item style="width:100%;">
          <el-button type="primary" native-type="submit" style="width:100%;" @click.native.prevent="handleLogin" :loading="logining">登录</el-button>
        </el-form-item>
      </el-form>
      <!-- <div class="sns-login">
        <hr>
        <span class="info">或使用以下账号登录</span>
        <div class="btn-group">
          <el-popover
            ref="popover1"
            placement="top"
            width="300"
            trigger="click"
            @show="showQrcode">
            <div id="qywx-qrcode"></div>
          </el-popover>
          <el-button type="primary" plain v-popover:popover1><img src="../assets/qywx_logo.png" height="14" style=""> 企业微信APP 扫一扫</el-button>

        </div>
      </div> -->
      <div class="footer">
        <p class="text-center">Copyright © 2018 Hudson Community. 浙ICP备17004371号-2</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mobileValidator } from '../util/validator'
import bus from '../util/bus'
import store from '../util/store'
// eslint-disable-next-line
import { sendMobileVerify, mobileVerifyLogin, userInfo } from '../api/api'

export default {
  data () {
    return {
      logining: false,
      account: {
        mobile: localStorage.getItem('hudson.community.user.mobile') || '',
        verifyCode: ''
      },
      rules: {
        mobile: [
          { required: true, message: '请输入手机号', trigger: 'blur' },
          { min: 11, max: 11, message: '手机号长度需为11位', trigger: 'blur' },
          { validator: mobileValidator, trigger: 'blur' }
        ],
        verifyCode: [
          { required: true, message: '请输入验证码', trigger: 'blur' }
        ]
      },
      verifyCodeSent: false,
      countDown: 60
    }
  },
  methods: {
    handleLogin () {
      this.$refs.AccountFrom.validate(valid => {
        if (valid) {
          this.logining = true
          var loginParams = {
            mobile: this.account.mobile,
            verifyCode: this.account.verifyCode
          }
          localStorage.setItem('hudson.community.user.mobile', this.account.mobile)

          // this.goWithToken('demotoken')
          this.logining = false

          mobileVerifyLogin(loginParams)
            .then(data => {
              let token = data.result.token
              if (token) this.goWithToken(token)
            })
            .finally(() => {
              this.logining = false
            })
        }
      })
    },
    sendVerifyCode () {
      let params = { mobile: this.account.mobile }
      this.account.verifyCode = ''
      sendMobileVerify(params).then(() => {
        this.verifyCodeSent = true
        this.countDown = 60
        let countDownInvertal = setInterval(() => {
          this.countDown--
          if (this.countDown <= 0) {
            clearInterval(countDownInvertal)
            this.verifyCodeSent = false
          }
        }, 1000)

        // 提示
        this.$message('验证码已发送，请在手机上查看。')
      })
    },
    goWithToken (token) {
      let loading = this.$loading()
      store.setToken(token)
      userInfo({})
        .then(data => {
          if (data.resCode === '0') {
            store.userInfo = data.result
            this.$message({ message: '登录成功!', type: 'success' })
            setTimeout(() => {
              this.$router.push({ path: '/dashboard' })
            }, 2000)
          } else {
            this.$message({
              message: `登录失败: ${data.resDesc}`,
              type: 'error'
            })
          }
        })
        .catch(() => { })
        .finally(() => {
          loading.close()
        })
    }
  },
  created () {
    // 监听Api接口错误
    bus.$on('apiError', data => {
      if (!data || !data.resCode || data.resCode === '401') return
      // 提示错误
      this.$message({
        message: data.resDesc,
        type: 'error'
      })
      // 401：需要登录
      // if (data.resCode === '401') {
      //   this.$router.push({path: '/login'})
      // }
    })

    // 监听网络错误
    bus.$on('networkError', data => {
      if (!data) return
      // 提示错误
      this.$message({
        message: data,
        type: 'error'
      })
    })

    let token =
      this.$route.query.token || localStorage.getItem('hudson.community.token')
    if (token) {
      this.goWithToken(token)
    }
  }
}
</script>
<style lang="scss" scoped>
.main {
  padding-top: 150px;
}
.login-container {
  margin-left: auto;
  margin-right: auto;
  width: 360px;
  border: solid 1px #ccc;
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  .bg {
    position: fixed;
    z-index: -1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: url("../assets/login_bg.png") center center no-repeat;
    background-size: cover;
  }
  .sns-login {
    position: relative;
    margin-top: 50px;
    .info {
      position: absolute;
      top: -6px;
      left: 50%;
      transform: translate(-50%, 0);
      padding: 0 10px;
      width: 120px;
      text-align: center;
      background-color: #fff;
      font-size: 12px;
      color: #666;
      line-height: 1;
    }
    .btn-group {
      text-align: center;
      padding-top: 10px;
    }
  }
}

#qywx-qrcode {
  width: 300px;
  height: 405px;
}
.footer {
  position: absolute;
  bottom: 10px;
  left: 0;
  right: 0;
  text-align: center;
  p {
    font-size: 12px;
    color: #fff;
  }
}
</style>
