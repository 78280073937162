import Vue from 'vue'
import Router from 'vue-router'
import Home from '@/components/Home'
import Login from '@/components/Login'
import Dashboard from '@/components/dashboard/index'
// import Neighbor from '@/components/Neighbor'
// import Education from '@/components/Education'
// import Health from '@/components/Health'
import Startup from '@/components/Startup'
import Construction from '@/components/Construction'
// import Traffic from '@/components/Traffic'
import LowCarbon from '@/components/LowCarbon'
import Service from '@/components/Service'
import Govern from '@/components/Govern'
// import Security from '@/components/Security'
// import Manage from '@/components/Manage'
// import FireProtection from '@/components/FireProtection'
import Frame from '@/components/Frame'
// import Business from '@/components/Business'

Vue.use(Router)

let router = new Router({
  // mode: 'history',
  routes: [{
    path: '/',
    redirect: '/login'
  }, {
    path: '/login',
    name: 'Login',
    component: Login
  }, {
    path: '/dashboard',
    name: 'Home',
    component: Home,
    leaf: true, // 只有一个节点
    menuShow: true,
    iconCls: 'fa fa-home fa-fw', // 图标样式class
    children: [{
      path: '/dashboard',
      component: Dashboard,
      name: '首页',
      menuShow: true
    }]
  }, {
    path: '/neighbor',
    name: 'Neighbor',
    component: Home,
    leaf: true, // 只有一个节点
    menuShow: true,
    iconCls: 'fa fa-home fa-fw', // 图标样式class
    children: [{
      path: '/neighbor',
      component: Frame,
      props: {src: 'https://community.hudsonpower.cn/neighbor/index.html'},
      name: '未来邻里',
      menuShow: true
    }]
  }, {
    path: '/education',
    name: 'education',
    component: Home,
    leaf: true, // 只有一个节点
    menuShow: true,
    iconCls: 'fa fa-home fa-fw', // 图标样式class
    children: [{
      path: '/education',
      component: Frame,
      props: {src: 'https://community.hudsonpower.cn/edu/index.html'},
      name: '未来教育',
      menuShow: true
    }]
  }, {
    path: '/med',
    name: 'med',
    component: Home,
    leaf: true, // 只有一个节点
    menuShow: true,
    iconCls: 'fa fa-home fa-fw', // 图标样式class
    children: [{
      path: '/med',
      component: Frame,
      props: {src: 'https://community.hudsonpower.cn/med/index.html'},
      name: '未来健康',
      menuShow: true
    }]
  }, {
    path: '/startup',
    name: 'startup',
    component: Home,
    leaf: true, // 只有一个节点
    menuShow: true,
    iconCls: 'fa fa-home fa-fw', // 图标样式class
    children: [{
      path: '/startup',
      component: Startup,
      name: '未来创业',
      menuShow: true
    }]
  }, {
    path: '/construction',
    name: 'construction',
    component: Home,
    leaf: true, // 只有一个节点
    menuShow: true,
    iconCls: 'fa fa-home fa-fw', // 图标样式class
    children: [{
      path: '/construction',
      component: Construction,
      name: '未来建筑',
      menuShow: true
    }]
  }, {
    path: '/traffic',
    name: 'traffic',
    component: Home,
    leaf: true, // 只有一个节点
    menuShow: true,
    iconCls: 'fa fa-home fa-fw', // 图标样式class
    children: [{
      path: '/traffic',
      component: Frame,
      name: '未来交通',
      props: () => {
        return {src: 'https://community.hudsonpower.cn/park/#/dashboard?embed=1&orgId=' + localStorage.getItem('hudson.community.selectMerchantOpenId')}
      },
      menuShow: true
    }]
  }, {
    path: '/lowCarbon',
    name: 'lowCarbon',
    component: Home,
    leaf: true, // 只有一个节点
    menuShow: true,
    iconCls: 'fa fa-home fa-fw', // 图标样式class
    children: [{
      path: '/lowCarbon',
      component: LowCarbon,
      name: '未来低碳',
      menuShow: true
    }]
  }, {
    path: '/service',
    name: 'service',
    component: Home,
    leaf: true, // 只有一个节点
    menuShow: true,
    iconCls: 'fa fa-home fa-fw', // 图标样式class
    children: [{
      path: '/service',
      component: Service,
      name: '未来服务',
      menuShow: true
    }]
  }, {
    path: '/govern',
    name: 'govern',
    component: Home,
    leaf: true, // 只有一个节点
    menuShow: true,
    iconCls: 'fa fa-home fa-fw', // 图标样式class
    children: [{
      path: '/govern',
      component: Govern,
      name: '未来治理',
      menuShow: true
    }]
  }, {
    path: '/',
    name: 'security',
    component: Home,
    leaf: true, // 只有一个节点
    menuShow: true,
    iconCls: 'fa fa-home fa-fw', // 图标样式class
    children: [{
      path: '/security',
      component: Frame,
      // props: {src: 'http://localhost:8085/'},
      props: {src: 'https://community.hudsonpower.cn/security/index.html'},
      name: '未来安防',
      menuShow: true
    }]
  }, {
    path: '/manage',
    name: 'manage',
    component: Home,
    leaf: true, // 只有一个节点
    menuShow: true,
    iconCls: 'fa fa-home fa-fw', // 图标样式class
    children: [{
      path: '/manage',
      component: Frame,
      props: {src: 'https://community.hudsonpower.cn/property/index.html'},
      name: '未来物业',
      menuShow: true
    }]
  }, {
    path: '/business',
    name: 'business',
    component: Home,
    leaf: true, // 只有一个节点
    menuShow: true,
    iconCls: 'fa fa-home fa-fw', // 图标样式class
    children: [{
      path: '/business',
      component: Frame,
      props: () => {
        return {src: 'https://bes.hudsonpower.cn/#/dashboard?embed=1&orgId=' + localStorage.getItem('hudson.community.selectMerchantOpenId')}
      },
      // props: {src: 'https://community.hudsonpower.cn/biz/index.html'},
      name: '未来商业',
      menuShow: true
    }]
  }, {
    path: '/fireProtection',
    name: 'fireProtection',
    component: Home,
    leaf: true, // 只有一个节点
    menuShow: true,
    iconCls: 'fa fa-home fa-fw', // 图标样式class
    children: [{
      path: '/fireProtection',
      component: Frame,
      props: () => {
        return {src: 'https://console.hudsonpower.cn/#/dashboard?embed=1&orgId=' + localStorage.getItem('hudson.community.selectMerchantOpenId')}
      },
      // props: {src: 'https://community.hudsonpower.cn/fp/index.html'},
      name: '未来消防',
      menuShow: true
    }]
  }, {
    path: '/waste',
    name: 'waste',
    component: Home,
    leaf: true, // 只有一个节点
    menuShow: true,
    iconCls: 'fa fa-recycle fa-fw', // 图标样式class
    children: [{
      path: '/waste',
      component: Frame,
      props: {src: 'https://waste.hudsonpower.cn/#/home?embed=1'},
      name: '垃圾回收',
      menuShow: true
    }]
  // }, {
  //   path: '/',
  //   name: 'fireProtection',
  //   component: Home,
  //   leaf: true, // 只有一个节点
  //   menuShow: true,
  //   iconCls: 'fa fa-home fa-car', // 图标样式class
  //   children: [{
  //     path: '/park',
  //     component: Frame,
  //     props: {src: 'http://localhost:8083/'},
  //     name: '未来停车',
  //     menuShow: true
    // }]
  }, {
    path: '/*', // otherwise redirect to dashboard
    redirect: '/dashboard'
  }]
})

export default router
