<template>
  <div style="width: 100%; height: 100%; overflow: hidden;" v-resize:debounce="onResize">
    <div style="width: 1920px; height: 1080px; transform-origin: left top; display: flex; flex-direction: column;" :style="{transform: scaleStyle}">
      <div style="display: flex; flex-direction: column; flex-grow:1; flex:2" class="body">
        <div class="box" style="display: flex; flex-direction: column; flex-grow:1; flex:1; margin: 5px;">
          <div class="hd"><h4>社区创业中心</h4></div>
          <div style="display: flex; flex-direction: row; flex-grow:1; flex:1">
            <div style="display: flex; flex-direction: column; flex-grow:1; flex:1; margin:5px;">
            </div>
            <div style="display: flex; flex-direction: column; flex-grow:1; flex:2; margin:5px;">
              <div class="bd" style="display: block;">
                <ul>
                  <li class="list-text"><h1>创业资讯</h1></li>
                  <li class="list-text"><h1>创业导师</h1></li>
                </ul>
                <h1 style ="text-align:center; border:2px solid #a1a1a1; padding:10px 40px; background:#13C321; width:350px;border-radius:25px; width:25%; color: #134EC3">创业资讯</h1>
              </div>
              
            </div>
            <div style="display: flex; flex-direction: column; flex-grow:1; flex:2; margin:5px; justify-content: center">
              <div class="bd" style="display: block;">
                <ul>
                  <li class="list-text"><h1>创业互动</h1></li>
                  <li class="list-text"><h1>创业故事 </h1></li>
                </ul>
                <h1 style ="text-align:center; border:2px solid #a1a1a1; padding:10px 40px; width:350px;border-radius:25px; width:25%; color: #0F87F1">创业故事</h1>
              </div>
            </div>
            <div style="display: flex; flex-direction: column; flex-grow:1; flex:2; margin:5px; justify-content: center">
              <div class="bd" style="display: block;">
                <ul>
                  <li class="list-text"><h1>创业政策</h1></li>
                  <li class="list-text"><h1>创业联盟</h1></li>
                </ul>
                <h1 style ="text-align:center; border:2px solid #a1a1a1; padding:10px 40px; width:350px;border-radius:25px; width:25%; color: #0F87F1">创业联盟</h1>
              </div>
            </div>
            <div style="display: flex; flex-direction: column; flex-grow:1; flex:2; margin:5px; justify-content: center">
              <div class="bd" style="display: block;">
                <ul>
                  <li class="list-text"><h1>创业服务</h1></li>
                  <li class="list-text"><h1>大学生创业</h1></li>
                </ul>
                <h1 style ="text-align:center; border:2px solid #a1a1a1; padding:10px 40px; background:#6FADE3; width:350px;border-radius:25px; width:25%; color: #F4FF04">创业政策</h1>
              </div>
            </div>
            <div style="display: flex; flex-direction: column; flex-grow:1; flex:1; margin:5px;">
            </div>
          </div>
        </div>
        <div style="display: flex; flex-direction: row; flex-grow:1; flex:1">
          <div class="box" style="display: flex; flex-direction: column; flex-grow:1; flex:1; margin: 5px">
            <div class="hd"><h4>创业项目</h4></div>
            <div class="bd" style="display: block;">
              <ul>
                <li class="list-text"><h1>区块链在食品溯源的应用</h1></li>
                <li class="list-text"><h1>区块链在物流的应用</h1></li>
                <li class="list-text"><h1>大数据挖掘在智慧消防的应用</h1></li>
                <li class="list-text"><h1>人工智能识别在消防通道的应用</h1></li>
                <li class="list-text"><h1>人工智能识别在管道泄漏的应用</h1></li>
              </ul>
            </div>
          </div>
          <div class="box" style="display: flex; flex-direction: column; flex-grow:1; flex:1; margin: 5px">
            <div class="hd"><h4>创业论坛</h4></div>
            <div class="bd" style="display: block;">
              <ul>
                <li class="list-text"><h1>政策</h1></li>
                <li class="list-text"><h1>新闻</h1></li>
                <li class="list-text"><h1>观察</h1></li>
                <li class="list-text"><h1>大学创业</h1></li>
              </ul>
            </div>
          </div>
          <div class="box" style="display: flex; flex-direction: column; flex-grow:1; flex:1; margin: 5px">
            <div class="hd"><h4>创业展会</h4></div>
            <div class="bd" style="display: block;">
              <ul>
                <li class="list-text"><h1>华东地区</h1></li>
                <li class="list-text"><h1>华南地区</h1></li>
                <li class="list-text"><h1>华北地区</h1></li>
                <li class="list-text"><h1>北美地区</h1></li>
                <li class="list-text"><h1>亚洲地区</h1></li>
              </ul>
            </div>
          </div>
        </div>
        <div style="display: flex; flex-direction: row; flex-grow:1; flex:1">
          <div class="box" style="display: flex; flex-direction: column; flex-grow:1; flex:1; margin: 5px">
            <div class="hd"><h4>创业融资、基金</h4></div>
            <div class="bd" style="display: block;">
              <ul>
                <li class="list-text"><h1>资金</h1></li>
                <li class="list-text"><h1>风投</h1></li>
                <li class="list-text"><h1>政府支持政策</h1></li>
                <li class="list-text"><h1>银行贷款</h1></li>
              </ul>
            </div>
          </div>
          <div class="box" style="display: flex; flex-direction: column; flex-grow:1; flex:1; margin: 5px">
            <div class="hd"><h4>创业活动、故事</h4></div>
            <div class="bd" style="display: block;">
              <ul>
                <li class="list-text"><h1>区块链创业</h1></li>
                <li class="list-text"><h1>大数据</h1></li>
                <li class="list-text"><h1>人工智能</h1></li>
                <li class="list-text"><h1>物联网</h1></li>
                <li class="list-text"><h1>智能安防</h1></li>
              </ul>
            </div>
          </div>
          <div class="box" style="display: flex; flex-direction: column; flex-grow:1; flex:1; margin: 5px">
            <div class="hd"><h4>创业学院、课程 </h4></div>
            <div class="bd" style="display: block;">
              <ul>
                <li class="list-text"><h1>指南</h1></li>
                <li class="list-text"><h1>书架</h1></li>
                <li class="list-text"><h1>培训</h1></li>
                <li class="list-text"><h1>商业模式</h1></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      
    </div>
  </div>
</template>
<script>
import resize from 'vue-resize-directive'
import * as echarts from 'echarts'

export default {
  directives: {
    resize
  },
  data () {
    return {
      activeName: 'first',
      scaleStyle: ''
    }
  },
  methods: {
    onResize (el) {
      let scaleX = el.clientWidth / 1920
      let scaleY = el.clientHeight / 1080
      scaleX = scaleX < 0.5 ? 0.5 : scaleX
      scaleY = scaleY < 0.5 ? 0.5 : scaleY
      scaleX = scaleX > 2 ? 2 : scaleX
      scaleY = scaleY > 2 ? 2 : scaleY

      // 比例调节
      if (scaleY / scaleX > 1.25) scaleY = scaleX * 1.25

      this.scaleStyle = `scale(${scaleX}, ${scaleY})`
    },
    handleClick (tab, event) {
      console.log(tab, event)
    },
    init () {
      this.drawInvestment('investment')
    },
    drawInvestment (id) {
      var myChart = echarts.init(document.getElementById(id))
      var option = {
        title: {
          text: '动态数据',
          textStyle: {
            color: '#fff'
          },
          show: false
        },
        textStyle: {
          color: '#fff'
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#283b56'
            }
          }
        },
        legend: {
          data: ['最新成交价', '预购队列'],
          textStyle: {
            color: '#fff'
          }
        },
        dataZoom: {
          show: false,
          start: 0,
          end: 100
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: true,
            data: (function () {
              var now = new Date()
              var res = []
              var len = 10
              while (len--) {
                res.unshift(now.toLocaleTimeString().replace(/^\D*/, ''))
                now = new Date(now - 2000)
              }
              return res
            })()
          },
          {
            type: 'category',
            boundaryGap: true,
            data: (function () {
              var res = []
              var len = 10
              while (len--) {
                res.push(10 - len - 1)
              }
              return res
            })()
          }
        ],
        yAxis: [
          {
            type: 'value',
            scale: true,
            name: '价格',
            max: 30,
            min: 0,
            boundaryGap: [0.2, 0.2]
          },
          {
            type: 'value',
            scale: true,
            name: '预购量',
            max: 1200,
            min: 0,
            boundaryGap: [0.2, 0.2]
          }
        ],
        series: [
          {
            name: '预购队列',
            type: 'bar',
            xAxisIndex: 1,
            yAxisIndex: 1,
            data: (function () {
              var res = []
              var len = 10
              while (len--) {
                res.push(Math.round(Math.random() * 1000))
              }
              return res
            })()
          },
          {
            name: '最新成交价',
            type: 'line',
            data: (function () {
              var res = []
              var len = 0
              while (len < 10) {
                res.push((Math.random() * 10 + 5).toFixed(1) - 0)
                len++
              }
              return res
            })()
          }
        ]
      }

      var count = 11
      setInterval(function () {
        var axisData = (new Date()).toLocaleTimeString().replace(/^\D*/, '')
        var data0 = option.series[0].data
        var data1 = option.series[1].data
        data0.shift()
        data0.push(Math.round(Math.random() * 1000))
        data1.shift()
        data1.push((Math.random() * 10 + 5).toFixed(1) - 0)
        option.xAxis[0].data.shift()
        option.xAxis[0].data.push(axisData)
        option.xAxis[1].data.shift()
        option.xAxis[1].data.push(count++)
        myChart.setOption(option)
      }, 2100)
    }
  },
  mounted () {
    this.init()
  }
}
</script>

<style lang='scss' scoped>
@import "src/style/variables";
.head {
  box-sizing: border-box;
  margin-top: 10px;
  height: 50px;
  display: flex;
  &.alarm {
    animation-name: alarmframes;
    animation-duration: 2s;
    animation-timing-function: ease;
    animation-iteration-count: infinite;
  }

  .title {
    flex: 1;
    text-align: center;
    span {
      display: inline-block;
      background-color: #0f1e3e;
      border: solid 1px $--color-primary;
      border-radius: 10px;
      padding: 0 50px;
      box-shadow: 0 0 10px 0 $--color-primary inset;

      font-family: "Microsoft YaHei", "SimHei";
      // font-family: 'STHeiti', 'STXihei', 'SimHei';
      line-height: 48px;
      font-size: 28px;
      color: #fff;
      font-weight: 800;
      letter-spacing: 10px;
    }
  }
  .logo-bar {
    box-sizing: border-box;
    width: 210px;
    height: 50px;
    padding-top: 3px;
    box-sizing: border-box;
    margin-left: 10px;
    text-align: center;
    display: flex;

    background-color: #0f1e3e;
    box-sizing: border-box;
    border: solid 1px $--color-primary;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 $--color-primary inset;
  }

  .operation-bar {
    box-sizing: border-box;
    width: 210px;
    height: 50px;
    padding-top: 7px;
    box-sizing: border-box;
    margin-right: 10px;
    text-align: center;

    background-color: #0f1e3e;
    box-sizing: border-box;
    border: solid 1px $--color-primary;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 $--color-primary inset;
    .el-button {
      padding: 0;
      width: 32px;
      height: 32px;
      border: solid 1px #ccc;
      border-radius: 5px;
      .icon {
        color: #ccc;
      }
    }
    .el-button:hover {
      border: solid 1px #fff;
      .icon {
        color: #fff;
      }
    }
  }
}

.body {
  flex: 1;
  background-color: #0c1e5a;
  // background-color: #0c3d73;
  box-sizing: border-box;
  border: solid 1px $--color-primary;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  margin: 10px;
  box-shadow: 0 0 10px 0 $--color-primary inset;
  display: flex;
  flex-direction: column;
  &.alarm {
    animation-name: alarmframes;
    animation-duration: 2s;
    animation-timing-function: ease;
    animation-iteration-count: infinite;
  }
}

.el-row {
  padding: 10px;
}

.box {
  // background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  border: solid 2px #25c6fc;
  box-shadow: 0 0 20px 0 #ffffff99 inset;
  padding: 10px;
  display: flex;
  flex-direction: column;
  .hd {
    // background-color: rgba(0, 0, 0, .3);
    color: $--color-primary;
    height: 20px;
    padding: 5px 0;
    overflow: hidden;
    a {
      color: $--color-primary;
    }
    h4 {
      border-left: solid 5px $--color-primary;
      font-size: 16px;
      margin: 0;
      margin-left: 5px;
      padding: 0 10px;
      line-height: 20px;
      // text-align: center;
    }
  }
  .bd {
    // background-color: rgba(0, 0, 0, .5);
    flex: 1;
    height: 100px;
    //line-height: 30px;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .bd.dumy {
    background: rgba(255, 255, 255, 0.1);
  }
}
.list-text {
  color: white;
}

.el-table.table-dark {
  background-color: rgba(255, 255, 255, 0.1);
  /deep/ tr, /deep/ th, {
    background-color: transparent;
  }
  /deep/ .el-table__row {
    // background-color: rgba(255, 255, 255, .2);
    color: #fff;
  }
  
  /deep/ .el-table__row.hover-row td {
    color: #fff;
    background-color: rgba(255, 255, 255, .2);
  }
  /deep/ .el-table__body tr:hover > td {
    color: #fff;
    background-color: rgba(255, 255, 255, .2);
  }
  /deep/ .el-table__header th, .el-table__header tr {
    // background-color: rgba(255, 255, 255, .5);
    // color: #666;
  }
}

::-webkit-scrollbar {
  background-color: rgba(255, 255, 255, 0.1);
  width: 8px;
  height: 8px;
  border-radius: 2px;
}
::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 0.3);
}

.alarm-list {
  color: #fff;
  ul {
    padding: 0 1em;
    list-style: none;
    li {
      font-size: 1em;
      padding: 3px 0;
      a {
        color: #fff;
      }
      border-bottom: dashed 1px #999;
    }
  }
}

.operation-bar {
  background-color: rgba(0, 0, 0, 0.3);
  color: #fff;
  padding: 0 10px;
  box-sizing: border-box;
}

.statistic .bd {
  display: flex;
  align-items:Center;
  .value{
    font-size: 36px;
    color: #ccc;
    margin: auto;
  }
}

.text-white {
  color: #fff;
}
// .fullscreen {
//   position: fixed !important;
//   top: 0;
//   left: 0;
// }

// map 
/deep/ .anchorBL {
  opacity: .2;
}

.el-carousel__item h3 {
    position: absolute;
    width: 100%;
    top: 50%;
    text-align: center;
    color: #000;
    font-size: 24px;
    line-height: 40px;
  }

  // .el-carousel__item:nth-child(2n) {
  //    background-color: #99a9bf;
  // }
  
  // .el-carousel__item:nth-child(2n+1) {
  //    background-color: #d3dce6;
  // }

</style>