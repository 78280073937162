<template>
  <iframe :src="src" style="position: absolute; border: 0; margin: 0; width: 100%; height: 100%; overflow: hidden;" webkitallowfullscreen="" mozallowfullscreen="" allowfullscreen=""></iframe>
</template>

<script>
export default {
  props: {
    name: {
      src: String,
      default: 'https://www.hudsonpower.cn'
    }
  },
  data () {
    return {
    }
  },
  methods: {
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "src/style/variables";

</style>
