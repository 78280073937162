
<template>
  <div style="width: 100%; height: 100%; overflow: hidden;" v-resize:debounce="onResize">
    <div id="full-screen-container" :ref="ref">
      <template v-if="ready">
        <slot></slot>
      </template>
    </div>
  </div>
</template>

<script>
import resize from 'vue-resize-directive'

export default {
  name: 'FullScreenContainer',
  props: {
    minWidth: {
      type: Number,
      default: 1920
    }
  },
  directives: {
    resize
  },
  data () {
    return {
      ref: 'full-screen-container',
      allWidth: 0,
      scale: 0,
      datavRoot: '',
      ready: false
    }
  },
  mounted () {
    this.onResize()
  },
  methods: {
    onResize () {
      console.log('resize')

      const dom = this.$refs[this.ref]
      var scaleWidth = document.body.clientWidth / 1920
      var scaleHeight = document.body.clientHeight / 1080
      // var scale = scaleWidth < scaleHeight ? scaleWidth : scaleHeight
      dom.style.transform = `scaleX(${scaleWidth}) scaleY(${scaleHeight})`

      this.ready = true
    }
  }
}
</script>

<style lang="less">
#full-screen-container {
  // position: fixed;
  top: 0px;
  left: 0px;
  width: 1920px;
  height: 1080px;
  overflow: hidden;
  transform-origin: left top;
  z-index: 999;
}
</style>
