<template>
  <dv-border-box-7>
    <div class="hd">未来建筑</div>
    <div style="display: flex;">
      <div class="item">
        <div class="icon"><img src="./img/construction-1.png" width="56" height="56"/></div>
        <div class="number">100</div>
      </div>
      <div class="item">
        <div class="icon"><img src="./img/construction-2.png" width="56" height="56"/></div>
        <div class="number">100</div>
      </div>
      <div class="item">
        <div class="icon"><img src="./img/construction-3.png" width="56" height="56"/></div>
        <div class="number">100</div>
      </div>
    </div>
  </dv-border-box-7>
</template>

<script>
/* eslint-disable */
export default {
  data () {
    return {
      config: {
        data: [
          {
            name: '在线穿戴终端',
            value: 1450
          },
        ],
        colors: ['#e062ae', '#fb7293', '#e690d1', '#32c5e9', '#96bfff'],
        unit: '台',
        showValue: true
      }
    }
  },
  methods: {
    load() {
    },
  },
  mounted() {
    this.load()
  }
}
</script>

<style lang="less" scoped>
.hd {
  font-size: 24px;
  font-weight: 600;
}
.item {
  display: flex;
  padding: 10px;
  .icon {
    width: 50px;
    height: 50px;
  }
  .number {
    flex: 1;
    font-size: 36px;
    font-weight: 400;
  }

}
</style>