<template>
  <div style="width: 100%; height: 100%; overflow: hidden;" v-resize:debounce="onResize">
    <div style="width: 1920px; height: 1080px; transform-origin: left top; display: flex; flex-direction: column;" :style="{transform: scaleStyle}">
      <div style="display: flex; flex-direction: column; flex-grow:1; flex:1" class="body">
        <div style="display: flex; flex-direction: column; flex-grow:1; flex:1">
          <h2 class="text-center text-white">社区低碳能源管理中心</h2>
          <div style="display: flex; flex-direction: row; flex-grow:1; flex:1">
            <div class="box" style="display: flex; flex-direction: column; flex-grow:1; flex:1; margin: 5px;">
              <div class="hd" style="text-align: center"><h4>社区太阳能信息</h4></div>
              <div id="electric" style="display:flex; flex: 1"></div>
            </div>
            <div class="box" style="display: flex; flex-direction: column; flex-grow:1; flex:1; margin: 5px;">
              <div class="hd" style="text-align: center"><h4>供热、供冷系统信息</h4></div>
              <div style="display: flex; flex-direction: row; flex-grow:1; flex:1">
                <div class="box" style="display: flex; flex-direction: column; flex-grow:1; flex:1; margin:5px">
                  <ul>
                    <li  class="list-text"><h1>供热状态: <span style="display: inline-block;" class="text-primary">启动</span></h1></li>
                    <li  class="list-text"><h1>供热温度状态: <span style="display: inline-block;" class="text-primary">25摄氏度</span></h1></li>
                    <li  class="list-text"><h1>能源损耗: <span style="display: inline-block;" class="text-primary">12KWh</span></h1></li>
                    <li  class="list-text"><h1>价格: <span style="display: inline-block;" class="text-primary">15元</span></h1></li>
                    <li  class="list-text"><h1>建议: <span style="display: inline-block;" class="text-primary">你的能源消耗高于45%的家庭</span></h1></li>
                    <li  class="list-text"><h1>建议: <span style="display: inline-block;" class="text-primary">降低温度有助于节能</span></h1></li>
                  </ul>
                </div>
                <div class="box" style="display: flex; flex-direction: column; flex-grow:1; flex:1; margin:5px">
                  <ul>
                    <li  class="list-text"><h1>供冷状态 <span style="display: inline-block;" class="text-primary">未启动</span></h1></li>
                    <li  class="list-text"><h1>供冷温度状态 <span style="display: inline-block;" class="text-primary">未启动</span></h1></li>
                    <li  class="list-text"><h1>能源损耗 <span style="display: inline-block;" class="text-primary">未启动</span></h1></li>
                    <li  class="list-text"><h1>价格 <span style="display: inline-block;" class="text-primary">未启动</span></h1></li>
                    <li  class="list-text"><h1>建议 <span style="display: inline-block;" class="text-primary">未启动</span></h1></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style="display: flex; flex-direction: column; flex-grow:1; flex:1">
          <div style="display: flex; flex-direction: column; flex-grow:1; flex:1">
            <h2 class="text-center text-white">太阳能区块链交易网暨垃圾管理物联网</h2>
            <div style="display: flex; flex-direction: row; flex-grow:1; flex:1">
              <div class="box" style="display: flex; flex-direction: column; flex-grow:1; flex:1; margin: 5px;">
                <div class="hd" style="text-align: center"><h4>太阳能区块链“哈特币” 交易网</h4></div>
                <div class="box" style="display: flex; flex-direction: row; flex-grow:1; flex:1">
                  <ul>
                    <li  class="list-text"><h1>卖 <span style="display: inline-block; width: 40px;" class="text-primary">27</span><span style="display: inline-block; width: 40px;" class="text-success">100</span></h1></li>
                    <li  class="list-text"><h1>卖 <span style="display: inline-block; width: 40px;" class="text-primary">26</span><span style="display: inline-block; width: 40px;" class="text-success">2200</span></h1></li>
                    <li  class="list-text"><h1>卖 <span style="display: inline-block; width: 40px;" class="text-primary">25</span><span style="display: inline-block; width: 40px;" class="text-success">300</span></h1></li>
                    <li  class="list-text"><h1>卖 <span style="display: inline-block; width: 40px;" class="text-primary">24</span><span style="display: inline-block; width: 40px;" class="text-success">1900</span></h1></li>
                    <li  class="list-text"><h1>卖 <span style="display: inline-block; width: 40px;" class="text-primary">23</span><span style="display: inline-block; width: 40px;" class="text-success">3000</span></h1></li>
                  </ul>
                  <ul></ul>
                  <ul></ul>
                  <ul></ul>
                  <ul>
                    <li  class="list-text"><h1>买 <span style="display: inline-block; width: 40px;" class="text-primary">22</span><span style="display: inline-block; width: 40px;" class="text-success">5000</span></h1></li>
                    <li  class="list-text"><h1>买 <span style="display: inline-block; width: 40px;" class="text-primary">21</span><span style="display: inline-block; width: 40px;" class="text-success">2300</span></h1></li>
                    <li  class="list-text"><h1>买 <span style="display: inline-block; width: 40px;" class="text-primary">20</span><span style="display: inline-block; width: 40px;" class="text-success">1200</span></h1></li>
                    <li  class="list-text"><h1>买 <span style="display: inline-block; width: 40px;" class="text-primary">19</span><span style="display: inline-block; width: 40px;" class="text-success">500</span></h1></li>
                    <li  class="list-text"><h1>买 <span style="display: inline-block; width: 40px;" class="text-primary">18</span><span style="display: inline-block; width: 40px;" class="text-success">100</span></h1></li>
                  </ul>
                </div>
              </div>
              <div class="box" style="display: flex; flex-direction: column; flex-grow:1; flex:1; margin: 5px;">
                <div class="hd" style="text-align: center"><h4>垃圾管理物联网</h4></div>
                <div class="box" style="display: flex; flex-direction: row; flex-grow:1; flex:1">
                  <ul>
                    <li  class="list-text"><h1>1-302房间</h1></li>
                    <li  class="list-text"><h1>2-410房间</h1></li>
                    <li  class="list-text"><h1>3-506房间</h1></li>
                    <li  class="list-text"><h1>4-602房间</h1></li>
                    <li  class="list-text"><h1>5-710房间</h1></li>
                  </ul>
                  <ul></ul>
                  <ul></ul>
                  <ul></ul>
                  <ul>
                    <li  class="list-text"><h1>6-501房间</h1></li>
                    <li  class="list-text"><h1>7-310房间</h1></li>
                    <li  class="list-text"><h1>8-206房间</h1></li>
                    <li  class="list-text"><h1>9-902房间</h1></li>
                    <li  class="list-text"><h1>10-110房间</h1></li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import resize from 'vue-resize-directive'
import * as echarts from 'echarts'

export default {
  directives: {
    resize
  },
  data () {
    return {
      scaleStyle: ''
    }
  },
  methods: {
    onResize (el) {
      let scaleX = el.clientWidth / 1920
      let scaleY = el.clientHeight / 1080
      scaleX = scaleX < 0.5 ? 0.5 : scaleX
      scaleY = scaleY < 0.5 ? 0.5 : scaleY
      scaleX = scaleX > 2 ? 2 : scaleX
      scaleY = scaleY > 2 ? 2 : scaleY

      // 比例调节
      if (scaleY / scaleX > 1.25) scaleY = scaleX * 1.25

      this.scaleStyle = `scale(${scaleX}, ${scaleY})`
    },
    init () {
      this.drawElectric('electric')
    },
    drawElectric (id) {
      var myChart = echarts.init(document.getElementById(id))
      var option = {
        backgroundColor: '',
        textStyle: {
          color: '#fff'
        },
        color: ['#25C6FC', '#67c23a', '#FFBD10', '#FF401D'],
        title: {
          text: '瞬时用水与瞬时用电',
          show: false,
          textStyle: {
            color: '#fff'
          }
        },
        legend: {
          data: ['瞬时用水', '瞬时用电'],
          textStyle: {
            color: '#fff'
          }
        },
        dataZoom: {
          show: false,
          start: 0,
          end: 100
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: true,
            data: (function () {
              var now = new Date()
              var res = []
              var len = 10
              while (len--) {
                res.unshift(now.toLocaleTimeString().replace(/^\D*/, ''))
                now = new Date(now - 2000)
              }
              return res
            })()
          },
          {
            type: 'category',
            boundaryGap: true,
            data: (function () {
              var res = []
              var len = 10
              while (len--) {
                res.push(10 - len - 1)
              }
              return res
            })()
          }
        ],
        yAxis: [
          {
            type: 'value',
            scale: true,
            name: '用水量',
            max: 30,
            min: 0,
            boundaryGap: [0.2, 0.2]
          },
          {
            type: 'value',
            scale: true,
            name: '度数',
            max: 1200,
            min: 0,
            boundaryGap: [0.2, 0.2]
          }
        ],
        series: [
          {
            name: '瞬时用电',
            type: 'line',
            xAxisIndex: 1,
            yAxisIndex: 1,
            data: (function () {
              var res = []
              var len = 10
              while (len--) {
                res.push(Math.round(Math.random() * 1000))
              }
              return res
            })()
          },
          {
            name: '瞬时用水',
            type: 'line',
            data: (function () {
              var res = []
              var len = 0
              while (len < 10) {
                res.push((Math.random() * 10 + 5).toFixed(1) - 0)
                len++
              }
              return res
            })()
          }
        ]
      }

      var count = 11
      setInterval(function () {
        var axisData = (new Date()).toLocaleTimeString().replace(/^\D*/, '')
        var data0 = option.series[0].data
        var data1 = option.series[1].data
        data0.shift()
        data0.push(Math.round(Math.random() * 1000))
        data1.shift()
        data1.push((Math.random() * 10 + 5).toFixed(1) - 0)
        option.xAxis[0].data.shift()
        option.xAxis[0].data.push(axisData)
        option.xAxis[1].data.shift()
        option.xAxis[1].data.push(count++)
        myChart.setOption(option)
      }, 2100)
    }
  },
  mounted () {
    this.init()
  }
}
</script>

<style lang='scss' scoped>
@import "src/style/variables";
.head {
  box-sizing: border-box;
  margin-top: 10px;
  height: 50px;
  display: flex;
  &.alarm {
    animation-name: alarmframes;
    animation-duration: 2s;
    animation-timing-function: ease;
    animation-iteration-count: infinite;
  }

  .title {
    flex: 1;
    text-align: center;
    span {
      display: inline-block;
      background-color: #0f1e3e;
      border: solid 1px $--color-primary;
      border-radius: 10px;
      padding: 0 50px;
      box-shadow: 0 0 10px 0 $--color-primary inset;

      font-family: "Microsoft YaHei", "SimHei";
      // font-family: 'STHeiti', 'STXihei', 'SimHei';
      line-height: 48px;
      font-size: 28px;
      color: #fff;
      font-weight: 800;
      letter-spacing: 10px;
    }
  }
  .logo-bar {
    box-sizing: border-box;
    width: 210px;
    height: 50px;
    padding-top: 3px;
    box-sizing: border-box;
    margin-left: 10px;
    text-align: center;
    display: flex;

    background-color: #0f1e3e;
    box-sizing: border-box;
    border: solid 1px $--color-primary;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 $--color-primary inset;
  }

  .operation-bar {
    box-sizing: border-box;
    width: 210px;
    height: 50px;
    padding-top: 7px;
    box-sizing: border-box;
    margin-right: 10px;
    text-align: center;

    background-color: #0f1e3e;
    box-sizing: border-box;
    border: solid 1px $--color-primary;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 $--color-primary inset;
    .el-button {
      padding: 0;
      width: 32px;
      height: 32px;
      border: solid 1px #ccc;
      border-radius: 5px;
      .icon {
        color: #ccc;
      }
    }
    .el-button:hover {
      border: solid 1px #fff;
      .icon {
        color: #fff;
      }
    }
  }
}

.body {
  flex: 1;
  background-color: #0c1e5a;
  // background-color: #0c3d73;
  box-sizing: border-box;
  border: solid 1px $--color-primary;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  margin: 10px;
  box-shadow: 0 0 10px 0 $--color-primary inset;
  display: flex;
  flex-direction: column;
  &.alarm {
    animation-name: alarmframes;
    animation-duration: 2s;
    animation-timing-function: ease;
    animation-iteration-count: infinite;
  }
}

.el-row {
  padding: 10px;
}

.list-text {
  color: white;
}
.box {
  // background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  border: solid 2px #25c6fc;
  box-shadow: 0 0 20px 0 #ffffff99 inset;
  padding: 10px;
  display: flex;
  flex-direction: column;
  .hd {
    // background-color: rgba(0, 0, 0, .3);
    color: $--color-primary;
    height: 20px;
    padding: 5px 0;
    overflow: hidden;
    a {
      color: $--color-primary;
    }
    h4 {
      border-left: solid 5px $--color-primary;
      font-size: 16px;
      margin: 0;
      margin-left: 5px;
      padding: 0 10px;
      line-height: 20px;
      // text-align: center;
    }
  }
  .bd {
    // background-color: rgba(0, 0, 0, .5);
    flex: 1;
    height: 100px;
    //line-height: 30px;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .bd.dumy {
    background: rgba(255, 255, 255, 0.1);
  }
}

.el-table.table-dark {
  background-color: rgba(255, 255, 255, 0.1);
  /deep/ tr, /deep/ th, {
    background-color: transparent;
  }
  /deep/ .el-table__row {
    // background-color: rgba(255, 255, 255, .2);
    color: #fff;
  }
  
  /deep/ .el-table__row.hover-row td {
    color: #fff;
    background-color: rgba(255, 255, 255, .2);
  }
  /deep/ .el-table__body tr:hover > td {
    color: #fff;
    background-color: rgba(255, 255, 255, .2);
  }
  /deep/ .el-table__header th, .el-table__header tr {
    // background-color: rgba(255, 255, 255, .5);
    // color: #666;
  }
}

::-webkit-scrollbar {
  background-color: rgba(255, 255, 255, 0.1);
  width: 8px;
  height: 8px;
  border-radius: 2px;
}
::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 0.3);
}

.alarm-list {
  color: #fff;
  ul {
    padding: 0 1em;
    list-style: none;
    li {
      font-size: 1em;
      padding: 3px 0;
      a {
        color: #fff;
      }
      border-bottom: dashed 1px #999;
    }
  }
}

.operation-bar {
  background-color: rgba(0, 0, 0, 0.3);
  color: #fff;
  padding: 0 10px;
  box-sizing: border-box;
}

.statistic .bd {
  display: flex;
  align-items:Center;
  .value{
    font-size: 36px;
    color: #ccc;
    margin: auto;
  }
}

.text-white {
  color: #fff;
}
// .fullscreen {
//   position: fixed !important;
//   top: 0;
//   left: 0;
// }

// map 
/deep/ .anchorBL {
  opacity: .2;
}

.el-carousel__item h3 {
    position: absolute;
    width: 100%;
    top: 50%;
    text-align: center;
    color: #000;
    font-size: 24px;
    line-height: 40px;
  }

  // .el-carousel__item:nth-child(2n) {
  //    background-color: #99a9bf;
  // }
  
  // .el-carousel__item:nth-child(2n+1) {
  //    background-color: #d3dce6;
  // }

</style>