<template>
  <div style="width: 100%; height: 100%; overflow: hidden;" v-resize:debounce="onResize">
    <div style="width: 1920px; height: 1080px; transform-origin: left top; display: flex; flex-direction: column;" :style="{transform: scaleStyle}">
      <div style="display: flex; flex-direction: column; flex-grow:1; flex:1" class="body">
        <div style="display: flex; flex-direction: column; flex-grow:1; flex:1">
          <h2 class="text-center text-white">社区治理</h2>
          <div style="display: flex; flex-direction: row; flex-grow:1; flex:1">
            <div class="box" style="display: flex; flex-direction: column; flex-grow:1; flex:1; margin: 5px">
              <div class="hd" style="text-align: center"><h4>议事会</h4></div>
              <ul >
                <li class="list-text"><h1>议事会名单</h1></li>
                <li class="list-text"><h1>议事章程</h1></li>
                <li class="list-text"><h1>议事议程</h1></li>
                <li class="list-text"><h1>居民提议</h1></li>
              </ul>
            </div>
            <div class="box" style="display: flex; flex-direction: column; flex-grow:1; flex:1; margin: 5px">
              <div class="hd" style="text-align: center"><h4>社区客厅</h4></div>
              <div style="display: flex; flex-direction: row; flex-grow:1; flex:1; margin: 5px">
                <ul>
                  <li class="list-text"><h1>物业：建筑物话题</h1></li>
                  <li class="list-text"><h1>物业：管道话题</h1></li>
                  <li class="list-text"><h1>物业：保安话题</h1></li>
                  <li class="list-text"><h1>物业：电气、燃气话题</h1></li>
                </ul>
                <ul></ul>
                <ul>
                  <li class="list-text"><h1>文化话题</h1></li>
                  <li class="list-text"><h1>子女教育话题</h1></li>
                  <li class="list-text"><h1>居民沙龙</h1></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div style="display: flex; flex-direction: row; flex-grow:1; flex:1">
          <div class="box" style="display: flex; flex-direction: column; flex-grow:1; flex:1; margin: 5px">
            <div class="hd"><h4>居委会</h4></div>
            <div class="bd" style="display: block;">
              <ul>
                <li class="list-text"><h1>人员名单</h1></li>
                <li class="list-text"><h1>当前事项</h1></li>
                <li class="list-text"><h1>投诉</h1></li>
                <li class="list-text"><h1>反馈</h1></li>
                <li class="list-text"><h1>备注</h1></li>
              </ul>
            </div>
          </div>
          <div class="box" style="display: flex; flex-direction: column; flex-grow:1; flex:1; margin: 5px">
            <div class="hd"><h4>业主委员会</h4></div>
            <div class="bd" style="display: block;">
              <ul>
                <li class="list-text"><h1>人员名单</h1></li>
                <li class="list-text"><h1>当前事项</h1></li>
                <li class="list-text"><h1>投诉</h1></li>
                <li class="list-text"><h1>反馈</h1></li>
                <li class="list-text"><h1>备注</h1></li>
              </ul>
            </div>
          </div>
          <div class="box" style="display: flex; flex-direction: column; flex-grow:1; flex:1; margin: 5px">
            <div class="hd"><h4>物业管理委员会 </h4></div>
            <div class="bd" style="display: block;">
              <ul>
                <li class="list-text"><h1>人员名单</h1></li>
                <li class="list-text"><h1>当前事项</h1></li>
                <li class="list-text"><h1>投诉</h1></li>
                <li class="list-text"><h1>反馈</h1></li>
                <li class="list-text"><h1>备注</h1></li>
              </ul>
            </div>
          </div>
        </div>
        <div class="box" style="display: flex; flex-direction: column; flex-grow:1; flex:1; margin: 5px;">
          <div class="hd" style="text-align: center"><h4>邻里活动中心</h4></div>
          <div class="box" style="display: flex; flex-direction: row; flex-grow:1; flex:1">
            <div style="display: flex; flex-direction: column; flex-grow:1; flex:1; margin:5px;">
            </div>
            <div style="display: flex; flex-direction: column; flex-grow:1; flex:2; margin:5px;">
              <div class="bd" style="display: block;">
                <ul>
                  <li class="list-text"><h1>综治管理平台</h1></li>
                </ul>
                <h1 style ="text-align:center; border:2px solid #a1a1a1; padding:10px 40px; background:#13C321; width:350px;border-radius:25px; width:40%; color: #134EC3">综治管理平台</h1>
              </div>
              
            </div>
            <div style="display: flex; flex-direction: column; flex-grow:1; flex:2; margin:5px; justify-content: center">
              <div class="bd" style="display: block;">
                <ul>
                  <li class="list-text"><h1>综和执法平台</h1></li>
                </ul>
                <h1 style ="text-align:center; border:2px solid #a1a1a1; padding:10px 40px; width:350px;border-radius:25px; width:40%; color: #0F87F1">综和执法平台</h1>
              </div>
            </div>
            <div style="display: flex; flex-direction: column; flex-grow:1; flex:2; margin:5px; justify-content: center">
              <div class="bd" style="display: block;">
                <ul>
                  <li class="list-text"><h1>市场监管平台</h1></li>
                </ul>
                <h1 style ="text-align:center; border:2px solid #a1a1a1; padding:10px 40px; width:350px;border-radius:25px; width:40%; color: #0F87F1">市场监管平台</h1>
              </div>
            </div>
            <div style="display: flex; flex-direction: column; flex-grow:1; flex:2; margin:5px; justify-content: center">
              <div class="bd" style="display: block;">
                <ul>
                  <li class="list-text"><h1>便民服务平台</h1></li>
                </ul>
                <h1 style ="text-align:center; border:2px solid #a1a1a1; padding:10px 40px; background:#6FADE3; width:350px;border-radius:25px; width:40%; color: #F4FF04">便民服务平台</h1>
              </div>
            </div>
            <div style="display: flex; flex-direction: column; flex-grow:1; flex:1; margin:5px;">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import resize from 'vue-resize-directive'
// import * as echarts from 'echarts'

export default {
  directives: {
    resize
  },
  data () {
    return {
      scaleStyle: ''
    }
  },
  methods: {
    onResize (el) {
      let scaleX = el.clientWidth / 1920
      let scaleY = el.clientHeight / 1080
      scaleX = scaleX < 0.5 ? 0.5 : scaleX
      scaleY = scaleY < 0.5 ? 0.5 : scaleY
      scaleX = scaleX > 2 ? 2 : scaleX
      scaleY = scaleY > 2 ? 2 : scaleY

      // 比例调节
      if (scaleY / scaleX > 1.25) scaleY = scaleX * 1.25

      this.scaleStyle = `scale(${scaleX}, ${scaleY})`
    },
    init () {
    }
  },
  mounted () {
    this.init()
  }
}
</script>

<style lang='scss' scoped>
@import "src/style/variables";
.list-text {
  color: white;
}
.head {
  box-sizing: border-box;
  margin-top: 10px;
  height: 50px;
  display: flex;
  &.alarm {
    animation-name: alarmframes;
    animation-duration: 2s;
    animation-timing-function: ease;
    animation-iteration-count: infinite;
  }

  .title {
    flex: 1;
    text-align: center;
    span {
      display: inline-block;
      background-color: #0f1e3e;
      border: solid 1px $--color-primary;
      border-radius: 10px;
      padding: 0 50px;
      box-shadow: 0 0 10px 0 $--color-primary inset;

      font-family: "Microsoft YaHei", "SimHei";
      // font-family: 'STHeiti', 'STXihei', 'SimHei';
      line-height: 48px;
      font-size: 28px;
      color: #fff;
      font-weight: 800;
      letter-spacing: 10px;
    }
  }
  .logo-bar {
    box-sizing: border-box;
    width: 210px;
    height: 50px;
    padding-top: 3px;
    box-sizing: border-box;
    margin-left: 10px;
    text-align: center;
    display: flex;

    background-color: #0f1e3e;
    box-sizing: border-box;
    border: solid 1px $--color-primary;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 $--color-primary inset;
  }

  .operation-bar {
    box-sizing: border-box;
    width: 210px;
    height: 50px;
    padding-top: 7px;
    box-sizing: border-box;
    margin-right: 10px;
    text-align: center;

    background-color: #0f1e3e;
    box-sizing: border-box;
    border: solid 1px $--color-primary;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 $--color-primary inset;
    .el-button {
      padding: 0;
      width: 32px;
      height: 32px;
      border: solid 1px #ccc;
      border-radius: 5px;
      .icon {
        color: #ccc;
      }
    }
    .el-button:hover {
      border: solid 1px #fff;
      .icon {
        color: #fff;
      }
    }
  }
}

.body {
  flex: 1;
  background-color: #0c1e5a;
  // background-color: #0c3d73;
  box-sizing: border-box;
  border: solid 1px $--color-primary;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  margin: 10px;
  box-shadow: 0 0 10px 0 $--color-primary inset;
  display: flex;
  flex-direction: column;
  &.alarm {
    animation-name: alarmframes;
    animation-duration: 2s;
    animation-timing-function: ease;
    animation-iteration-count: infinite;
  }
}

.el-row {
  padding: 10px;
}

.box {
  // background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  border: solid 2px #25c6fc;
  box-shadow: 0 0 20px 0 #ffffff99 inset;
  padding: 10px;
  display: flex;
  flex-direction: column;
  .hd {
    // background-color: rgba(0, 0, 0, .3);
    color: $--color-primary;
    height: 20px;
    padding: 5px 0;
    overflow: hidden;
    a {
      color: $--color-primary;
    }
    h4 {
      border-left: solid 5px $--color-primary;
      font-size: 16px;
      margin: 0;
      margin-left: 5px;
      padding: 0 10px;
      line-height: 20px;
      // text-align: center;
    }
  }
  .bd {
    // background-color: rgba(0, 0, 0, .5);
    flex: 1;
    height: 100px;
    //line-height: 30px;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .bd.dumy {
    background: rgba(255, 255, 255, 0.1);
  }
}

.el-table.table-dark {
  background-color: rgba(255, 255, 255, 0.1);
  /deep/ tr, /deep/ th, {
    background-color: transparent;
  }
  /deep/ .el-table__row {
    // background-color: rgba(255, 255, 255, .2);
    color: #fff;
  }
  
  /deep/ .el-table__row.hover-row td {
    color: #fff;
    background-color: rgba(255, 255, 255, .2);
  }
  /deep/ .el-table__body tr:hover > td {
    color: #fff;
    background-color: rgba(255, 255, 255, .2);
  }
  /deep/ .el-table__header th, .el-table__header tr {
    // background-color: rgba(255, 255, 255, .5);
    // color: #666;
  }
}

::-webkit-scrollbar {
  background-color: rgba(255, 255, 255, 0.1);
  width: 8px;
  height: 8px;
  border-radius: 2px;
}
::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 0.3);
}

.alarm-list {
  color: #fff;
  ul {
    padding: 0 1em;
    list-style: none;
    li {
      font-size: 1em;
      padding: 3px 0;
      a {
        color: #fff;
      }
      border-bottom: dashed 1px #999;
    }
  }
}

.operation-bar {
  background-color: rgba(0, 0, 0, 0.3);
  color: #fff;
  padding: 0 10px;
  box-sizing: border-box;
}

.statistic .bd {
  display: flex;
  align-items:Center;
  .value{
    font-size: 36px;
    color: #ccc;
    margin: auto;
  }
}

.text-white {
  color: #fff;
}
// .fullscreen {
//   position: fixed !important;
//   top: 0;
//   left: 0;
// }

// map 
/deep/ .anchorBL {
  opacity: .2;
}

.el-carousel__item h3 {
    position: absolute;
    width: 100%;
    top: 50%;
    text-align: center;
    color: #000;
    font-size: 24px;
    line-height: 40px;
  }

  // .el-carousel__item:nth-child(2n) {
  //    background-color: #99a9bf;
  // }
  
  // .el-carousel__item:nth-child(2n+1) {
  //    background-color: #d3dce6;
  // }

</style>