<template>
  <div style="width: 100%; height: 100%; overflow: hidden;" v-resize:debounce="onResize">
    <div style="width: 1920px; height: 1080px; transform-origin: left top; display: flex; flex-direction: column;" :style="{transform: scaleStyle}">
      <div style="display: flex; flex: 1; flex-direction: column; margin: 5px;" class="body" >
        <div>
          <h1 class="text-center text-white">社区建筑CIM管理中心</h1>
        </div>
        <div style="display: flex; flex: 1; align-items: stretch">
          <div class="box" style="width: 100px; flex:1; margin: 5px;">
            <div class="hd">
              <h4>社区BIM信息</h4>
            </div>
            <div class="bd">
              <h2 style="margin: 1em;" class="list-text">BIM档案室</h2>
                <el-carousel trigger="click">
                  <el-carousel-item key="1" label="A栋1楼">
                    <div class="text-center"><i class="fa fa-map-o" style="font-size: 20em;"></i></div>
                  </el-carousel-item>
                  <el-carousel-item key="2" label="A栋2楼">
                    <div class="text-center"><i class="fa fa-map" style="font-size: 20em;"></i></div>
                  </el-carousel-item>
                  <el-carousel-item key="3" label="B栋1楼">
                    <div class="text-center"><i class="fa fa-globe" style="font-size: 20em;"></i></div>
                  </el-carousel-item>
                </el-carousel>
            </div>
          </div>
          <div class="box" style="width: 100px; flex:1; margin: 5px;">
            <div class="hd">
              <h4>管廊系统物联网</h4>
            </div>
            <div class="bd">
              <el-row>
                <el-col :span="12" style="position: relative;">
                  <h2 style="margin: 1em;" class="list-text">用电（和路灯）管道系统</h2>

                  <svg width="390" height="320" viewBox="0 0 300 300" preserveAspectRatio="xMaxYMax" xmlns="http://www.w3.org/2000/svg" xmlns:svg="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                    <!-- Created with SVG-edit - http://svg-edit.googlecode.com/ -->
                    <g>
                      <title>Layer 1</title>
                      <a xlink:href="http://" id="svg_3">
                      <path fill-opacity="0.2" id="svg_1" d="m296.598633,148.079086l-72.799332,126.092148l-145.598633,0l-72.799316,-126.092148l72.799316,-126.092125l145.598633,0l72.799332,126.092125" stroke-linecap="null" stroke-linejoin="null" stroke-dasharray="null" stroke-width="5" stroke="#cccccc" fill="#ffffff"/>
                      </a>
                    </g>
                  </svg>
                  <div class="text-center" style="box-sizing: border-box; padding-top: 100px; position: absolute; top: 100px; left: 0; right: 0;">
                    <span style="font-size: 2em;" class="list-text">用电线路<br/>管道</span>
                  </div>
                </el-col>
                <el-col :span="12">
                  <h2 style="margin: 1em;" class="list-text">通讯线路管道系统</h2>
                  <div class="text-center" style="box-sizing: border-box; height: 350px; padding-top: 100px; background-color: rgba(255, 255, 255, .2); border: 6px solid #ccc; border-radius: 30px;">
                    <span style="font-size: 2em;" class="list-text">通讯线路<br/>管道系统</span>
                  </div>
                </el-col>
              </el-row>
            </div>
          </div>
        </div>
      </div>
      <div style="display: flex; flex: 1; flex-direction: column; margin: 5px;" class="body">
        <div>
          <h1 class="text-center text-white">建筑物管道系统</h1>
        </div>
        <div style="display: flex; flex: 1;">

          <div class="box" style="width: 100px; flex:1; margin: 5px;">
            <div class="hd">
              <h4>供水管道</h4>
            </div>
            <div class="bd">
            </div>
          </div>

          <div class="box" style="width: 100px; flex:1; margin: 5px;">
            <div class="hd">
              <h4>排污管道</h4>
            </div>
            <div class="bd">
            </div>
          </div>

          <div class="box" style="width: 100px; flex:1; margin: 5px;">
            <div class="hd">
              <h4>供热管道</h4>
            </div>
            <div class="bd">
            </div>
          </div>

          <div class="box" style="width: 100px; flex:1; margin: 5px;">
            <div class="hd">
              <h4>供冷管道</h4>
            </div>
            <div class="bd">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import resize from 'vue-resize-directive'
// import * as echarts from 'echarts'

export default {
  directives: {
    resize
  },
  data () {
    return {
      scaleStyle: ''
    }
  },
  methods: {
    onResize (el) {
      let scaleX = el.clientWidth / 1920
      let scaleY = el.clientHeight / 1080
      scaleX = scaleX < 0.5 ? 0.5 : scaleX
      scaleY = scaleY < 0.5 ? 0.5 : scaleY
      scaleX = scaleX > 2 ? 2 : scaleX
      scaleY = scaleY > 2 ? 2 : scaleY

      // 比例调节
      if (scaleY / scaleX > 1.25) scaleY = scaleX * 1.25

      this.scaleStyle = `scale(${scaleX}, ${scaleY})`
    },
    init () {
    }
  },
  mounted () {
    this.init()
  }
}
</script>

<style lang='scss' scoped>
@import "src/style/variables";
.list-text {
  color: white;
}
.head {
  box-sizing: border-box;
  margin-top: 10px;
  height: 50px;
  display: flex;
  &.alarm {
    animation-name: alarmframes;
    animation-duration: 2s;
    animation-timing-function: ease;
    animation-iteration-count: infinite;
  }

  .title {
    flex: 1;
    text-align: center;
    span {
      display: inline-block;
      background-color: #0f1e3e;
      border: solid 1px $--color-primary;
      border-radius: 10px;
      padding: 0 50px;
      box-shadow: 0 0 10px 0 $--color-primary inset;

      font-family: "Microsoft YaHei", "SimHei";
      // font-family: 'STHeiti', 'STXihei', 'SimHei';
      line-height: 48px;
      font-size: 28px;
      color: #fff;
      font-weight: 800;
      letter-spacing: 10px;
    }
  }
  .logo-bar {
    box-sizing: border-box;
    width: 210px;
    height: 50px;
    padding-top: 3px;
    box-sizing: border-box;
    margin-left: 10px;
    text-align: center;
    display: flex;

    background-color: #0f1e3e;
    box-sizing: border-box;
    border: solid 1px $--color-primary;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 $--color-primary inset;
  }

  .operation-bar {
    box-sizing: border-box;
    width: 210px;
    height: 50px;
    padding-top: 7px;
    box-sizing: border-box;
    margin-right: 10px;
    text-align: center;

    background-color: #0f1e3e;
    box-sizing: border-box;
    border: solid 1px $--color-primary;
    border-radius: 10px;
    box-shadow: 0 0 10px 0 $--color-primary inset;
    .el-button {
      padding: 0;
      width: 32px;
      height: 32px;
      border: solid 1px #ccc;
      border-radius: 5px;
      .icon {
        color: #ccc;
      }
    }
    .el-button:hover {
      border: solid 1px #fff;
      .icon {
        color: #fff;
      }
    }
  }
}

.body {
  flex: 1;
  background-color: #0c1e5a;
  box-sizing: border-box;
  border: solid 1px $--color-primary;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  margin: 10px;
  box-shadow: 0 0 10px 0 $--color-primary inset;
  display: flex;
  flex-direction: column;
  &.alarm {
    animation-name: alarmframes;
    animation-duration: 2s;
    animation-timing-function: ease;
    animation-iteration-count: infinite;
  }
}

.el-row {
  padding: 10px;
}

.box {
  // background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  border: solid 2px #25c6fc;
  box-shadow: 0 0 20px 0 #ffffff99 inset;
  padding: 10px;
  display: flex;
  flex-direction: column;
  .hd {
    // background-color: rgba(0, 0, 0, .3);
    color: $--color-primary;
    height: 20px;
    padding: 5px 0;
    overflow: hidden;
    a {
      color: $--color-primary;
    }
    h4 {
      border-left: solid 5px $--color-primary;
      font-size: 16px;
      margin: 0;
      margin-left: 5px;
      padding: 0 10px;
      line-height: 20px;
      // text-align: center;
    }
  }
  .bd {
    // background-color: rgba(0, 0, 0, .5);
    flex: 1;
    height: 100px;
    //line-height: 30px;
    overflow-x: hidden;
    overflow-y: auto;
  }
  .bd.dumy {
    background: rgba(255, 255, 255, 0.1);
  }
}

.el-table.table-dark {
  background-color: rgba(255, 255, 255, 0.1);
  /deep/ tr, /deep/ th, {
    background-color: transparent;
  }
  /deep/ .el-table__row {
    // background-color: rgba(255, 255, 255, .2);
    color: #fff;
  }
  
  /deep/ .el-table__row.hover-row td {
    color: #fff;
    background-color: rgba(255, 255, 255, .2);
  }
  /deep/ .el-table__body tr:hover > td {
    color: #fff;
    background-color: rgba(255, 255, 255, .2);
  }
  /deep/ .el-table__header th, .el-table__header tr {
    // background-color: rgba(255, 255, 255, .5);
    // color: #666;
  }
}

::-webkit-scrollbar {
  background-color: rgba(255, 255, 255, 0.1);
  width: 8px;
  height: 8px;
  border-radius: 2px;
}
::-webkit-scrollbar-thumb {
  border-radius: 2px;
  background-color: rgba(255, 255, 255, 0.3);
}

.alarm-list {
  color: #fff;
  ul {
    padding: 0 1em;
    list-style: none;
    li {
      font-size: 1em;
      padding: 3px 0;
      a {
        color: #fff;
      }
      border-bottom: dashed 1px #999;
    }
  }
}

.operation-bar {
  background-color: rgba(0, 0, 0, 0.3);
  color: #fff;
  padding: 0 10px;
  box-sizing: border-box;
}

.statistic .bd {
  display: flex;
  align-items:Center;
  .value{
    font-size: 36px;
    color: #ccc;
    margin: auto;
  }
}

.text-white {
  color: #fff;
}
// .fullscreen {
//   position: fixed !important;
//   top: 0;
//   left: 0;
// }

// map 
/deep/ .anchorBL {
  opacity: .2;
}

.fontColor  {
  color : #ffffff

}

.el-carousel__item h3 {
    position: absolute;
    width: 100%;
    top: 50%;
    text-align: center;
    color: #000;
    font-size: 24px;
    line-height: 40px;
  }

  // .el-carousel__item:nth-child(2n) {
  //    background-color: #99a9bf;
  // }
  
  // .el-carousel__item:nth-child(2n+1) {
  //    background-color: #d3dce6;
  // }
  


</style>