<template>
  <div style="display: flex; flex-direction: column;">
    <div class="hd">未来创业</div>
    <div id="startup" style="flex: 1; width:100%"></div>
  </div>
</template>

<script>
import * as echarts from 'echarts'
import ecStat from 'echarts-stat'
/* eslint-disable */
export default {
  data () {
    return {}
  },
  methods: {
    load() {
      echarts.registerTransform(ecStat.transform.regression);
      var myChart = echarts.init(document.getElementById('startup'), 'dark');
      var option
      option = {
          tooltip: {
              trigger: 'item'
          },
          radar: {
              // shape: 'circle',
              indicator: [
                  { name: '创业展会', max: 100},
                  { name: '创业论坛', max: 100},
                  { name: '创业活动', max: 400},
                  { name: '创业学院', max: 400},
              ]
          },
          series: [{
              name: '创业内容',
              type: 'radar',
              data: [
                  {
                      value: [42, 30, 200, 350],
                      name: '预计'
                  },
                  {
                      value: [50, 40, 280, 260],
                      name: '实际'
                  }
              ]
          }]
      };
      // myChart.setOption(option);
      option && myChart.setOption(option);
    },
  },
  mounted() {
    this.load()
  }
}
</script>
<style lang="less" scoped>
.hd {
  font-size: 24px;
  font-weight: 600;
}
</style>
