<template>
  <div class="data-view">
    <full-screen-container class="full-screen-container" :min-width="1920">
      <div class="main-header">
        <!-- <div class="mh-left">
        </div>
        <div class="mh-middle">中国鞋都 产业大脑</div>
        <div class="mh-right">哈德森科技</div> -->
      </div>

      <dv-border-box-1 class="main-container">
        <div class="left-chart-container" style="display: flex; flex-direction: column;">
            <!-- left-top -->
          <div style="display: flex; flex: 1;">
            <!-- left-top-left -->
            <div style="display: flex; flex-direction: column; width: 500px;">
              <statistic style="height: 120px;"></statistic>
              <security style="flex: 1;"></security>
              <traffic style="flex: 1;"></traffic>
            </div>
            <!-- left-top-center -->
            <center style="flex: 1;"></center>
          </div>
          <!-- left-bottom -->
          <div style="display: flex; height: 300px;">
            <property style="flex: 1;"></property>
            <fire-protection style="flex: 1;"></fire-protection>
            <div style="display: flex; flex-direction: column; flex: 1;">
              <health></health>
              <construction></construction>
            </div>
          </div>
        </div>

        <dv-border-box-9 class="right-main-container">
          <div style="flex: 1; display: flex; flex-direction: column;">
            <low-carbon style="flex: 3;"></low-carbon>
            <waste style="flex: 3;"></waste>
            <div style="flex: 3; display: flex;">
              <service style="flex: 1;"></service>
              <startup style="flex: 1;"></startup>
            </div>
            <goverment style="flex: 2;"></goverment>
          </div>
        </dv-border-box-9>
      </dv-border-box-1>

    </full-screen-container>
  </div>
</template>

<script>
import FullScreenContainer from '@/components/common/FullScreenContainer.vue'
import LowCarbon from './LowCarbon.vue'
import Waste from './Waste.vue'
import Service from './Service.vue'
import Startup from './Startup.vue'
import Property from './Property.vue'
import FireProtection from './FireProtection.vue'
import Health from './Health.vue'
import Construction from './Construction.vue'
import Center from './Center.vue'
import Statistic from './Statistic.vue'
import Security from './Security.vue'
import Traffic from './Traffic.vue'
import Goverment from './Goverment.vue'

export default {
  name: 'DataView',
  components: {
    FullScreenContainer,
    LowCarbon,
    Waste,
    Service,
    Startup,
    Property,
    FireProtection,
    Health,
    Construction,
    Center,
    Statistic,
    Security,
    Traffic,
    Goverment
  },
  data () {
    return {}
  }
}
</script>

<style lang="less" scoped>
.data-view {
  width: 100%;
  height: 100%;
  background-color: #030409;
  color: #fff;

  .full-screen-container {
    background-image: url('./img/bg.png');
    background-size: 100% 100%;
    box-shadow: 0 0 3px blue;
    display: flex;
    flex-direction: column;
  }

  .main-header {
    // height: 80px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .mh-left {
      font-size: 20px;
      color: rgb(1,134,187);

      a:visited {
        color: rgb(1,134,187);
      }
    }

    .mh-middle {
      font-size: 48px;
      font-weight: 800;
    }

    .mh-left {
      width: 450px;
    }

    .mh-right {
      width: 450px;
      font-size: 28px;
      text-align: right;
      margin-right: 30px;
      font-weight: bold;
      margin-bottom: 10px;
    }
  }

  .main-container {
    height: calc(~"100% - 80px");

    /deep/ .border-box-content {
      padding: 20px;
      box-sizing: border-box;
      display: flex;
    }
  }

  .left-chart-container {
    width: 70%;
    padding: 10px;
    box-sizing: border-box;

    /deep/ .border-box-content {
      flex-direction: column;
    }
  }

  .right-main-container {
    width: 30%;
    padding-left: 5px;
    box-sizing: border-box;
  }
}
</style>
