<template>
  <dv-border-box-7>
    <div class="hd">未来健康</div>
    <dv-capsule-chart :config="config" style="width:100%;height:50px" />
  </dv-border-box-7>
</template>

<script>
/* eslint-disable */
export default {
  name: 'LeftChart1',
  data () {
    return {
      config: {
        data: [
          {
            name: '在线穿戴终端',
            value: 1450
          },
        ],
        colors: ['#e062ae', '#fb7293', '#e690d1', '#32c5e9', '#96bfff'],
        unit: '台',
        showValue: true
      }
    }
  },
  methods: {
    load() {
    },
  },
  mounted() {
    this.load()
  }
}
</script>

<style lang="less" scoped>
.hd {
  font-size: 24px;
  font-weight: 600;
}
</style>