<template>
<!-- eslint-disable -->
  <el-row class="container" v-loading="status < 2" @mousedown.native="userActivate">
    <!--头部-->
    <el-col :span="24" class="topbar-wrap">
      <div class="topbar-logo topbar-btn" style="margin-right: 20px;">
        <a href="#/">
          <img src="../assets/logo.png" width="50" height="50" style="padding-left:10px;"> <span style="font-weight:bold">未来社区</span>
        </a>
      </div>
      <div class="topbar-nav">
        <!--导航菜单-->
        <el-menu default-active="/dashboard" ref="leftMenu" mode="horizontal" :router="true" :collapse="collapsed">
          <template v-for="(item) in $router.options.routes" v-if="item.menuShow">
            <el-submenu v-if="(!item.leaf) &&hasMod(item.mod)" :index="item.path" popper-class="pop-menu">
              <template slot="title">
                <span slot="title" v-if="item.href"><a :href="item.href" target="_blank">{{item.name}}</a></span>
                <span slot="title">{{item.name}}</span>
              </template>
              <el-menu-item v-for="term in item.children" :key="term.path" :index="term.path" v-if="term.menuShow &&hasMod(item.mod) && (!term.role || hasRole(term.role))" :class="$route.path==term.path?'active':''">
                <span slot="title" v-if="item.href"><a :href="item.href" target="_blank">{{item.name}}</a></span>
                <span slot="title" v-else>{{term.name}}</span>
              </el-menu-item>
            </el-submenu>
            <el-menu-item v-else-if="item.leaf&&item.children&&item.children.length&&hasMod(item.mod)&&(!item.children[0].role || hasRole(item.children[0].role))" :index="item.children[0].path" :class="$route.path==item.children[0].path?'active':''">
              <span slot="title" v-if="item.children[0].href"><a :href="item.children[0].href" target="_blank">{{item.children[0].name}}</a></span>
              <span slot="title" v-else>{{item.children[0].name}}</span>
            </el-menu-item>
          </template>
        </el-menu>
      </div>
      <div class="topbar-account topbar-btn">
        <!-- <el-row>
          <el-col :span="20"> -->
        组织：
        <el-select v-model="merchantOpenId" @change="merchantChange" placeholder="组织" filterable class="merchantSelect">
          <el-option v-for="item in merchantList" :key="item.merchantOpenId" :label="item.merchantName" :value="item.merchantOpenId"></el-option>
        </el-select>
        <!-- </el-col>
          <el-col :span="4"> -->
        <el-dropdown trigger="click" class="userDropdownMenu">
          <span class="el-dropdown-link userinfo-inner">
            <img v-if="sysUserAvatar" :src="sysUserAvatar" style="width: 48px; height: 48px; vertical-align: middle;" />
            <i v-else class="fa fa-user-o fa-fw"></i>
             <!-- {{sysUserName}} -->
            <i class="fa fa-caret-down fa-fw"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item>
              <el-button size="mini" @click="fontSize(12)"><i class="fa fa-font" style="font-size: 12px;"></i></el-button>
              <el-button size="mini" @click="fontSize(14)"><i class="fa fa-font" style="font-size: 14px;"></i></el-button>
              <el-button size="mini" @click="fontSize(16)"><i class="fa fa-font" style="font-size: 16px;"></i></el-button>
            </el-dropdown-item>
            <!-- <el-dropdown-item>
                  <router-link to="/merchant/accountInfo"><span style="color: #555;font-size: 14px;">账户信息</span></router-link>
                </el-dropdown-item> -->
            <!-- <el-dropdown-item>
                  <router-link :to="'/user/changepwd'"><span style="color: #555;font-size: 14px;">修改密码</span></router-link>
                </el-dropdown-item> -->
            <el-dropdown-item @click.native="logout">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <!-- </el-col>
        </el-row> -->
      </div>
    </el-col>

    <!--中间-->
    <el-col :span="24" class="main" v-if="status >= 2">
      <!--左侧导航-->
      <!-- <aside :class="{showSidebar:!collapsed}"> -->
        <!--展开折叠开关-->
        <!-- <div class="menu-toggle" @click.prevent="collapse">
          <i class="fa fa-outdent fa-fw" v-show="!collapsed"></i>
          <i class="fa fa-indent fa-fw" v-show="collapsed"></i>
        </div> -->
        
      <!-- </aside> -->

      <!--右侧内容区-->
      <section class="content-container">
        <router-view></router-view>
      </section>
    </el-col>

  </el-row>
</template>

<script>
/* eslint-disable */
import bus from '../util/bus'
import store from '../util/store'
import { userInfo, myMerchant,  logout, logoutSync, merchantInfoDetail } from '../api/api'
export default {
  data () {
    return {
      status: 0,
      sysUserName: '',
      sysUserAvatar: '',
      collapsed: false,
      merchantOpenId: '',
      currentMerchantInfo: {},
      merchantList: [],
      lastActivateTime: new Date(),
      userActivateInterval: 0
    }
  },
  methods: {
    // 折叠导航栏
    collapse: function () {
      this.collapsed = !this.collapsed
    },
    hasRole (role) {
      return store.hasRole(role)
    },
    hasMod (mod) {
      return store.hasMod(mod)
    },
    logout () {
      var _this = this
      this.$confirm('确认退出吗?', '提示', {
        // type: 'warning'
      }).then(() => {
        logout().then(() => {
          console.log('logout')
        }).finally(() => {
          store.setToken('')
          _this.$router.push('/login')
        })
      })
    },
    merchantChange () {
      for (var item of this.merchantList) {
        if (this.merchantOpenId === item.merchantOpenId) {
          store.setMerchantInfo(item)
        }
      }
      setTimeout(() => {
        this.$router.push('/login')
      }, 500)
    },
    fontSize (value) {
      let body = document.getElementsByTagName('body')[0]
      if (body) {
        if (value >= 12 && value <= 18) {
          localStorage.setItem('fontSize', value)
          body.style.fontSize = `${value}px`
        }
      }
    },
    beforeunloadHandler () {
      this._beforeUnload_time = new Date().getTime()
    },
    unloadHandler: function (e) {
      let gapTime = new Date().getTime() - this._beforeUnload_time
      // 判断是窗口关闭还是刷新
      if (gapTime <= 5) {
        localStorage.setItem('logout', 'yes')
        logoutSync()
        localStorage.setItem('logout2', 'yes')
      }
    },
    userActivate () {
      this.lastActivateTime = new Date()
      // console.log(this.lastActivateTime)
    }
  },
  created () {
    // 监听用户信息修改事件
    bus.$on('setUserInfo', (userInfo) => {
      this.sysUserName = userInfo.name
      this.sysUserAvatar = userInfo.avatar
    })

    // 监听Api接口错误
    bus.$on('apiError', (data) => {
      if (!data || !data.resCode) return
      // 提示错误
      this.$message({
        message: data.resDesc,
        type: 'error'
      })
      // 401：需要登录
      if (data.resCode === '401') {
        this.$router.push({ path: '/login' })
      }
    })

    // 监听网络错误
    bus.$on('networkError', (data) => {
      if (!data) return
      // 提示错误
      this.$message({
        message: data,
        type: 'error'
      })
    })

    // 初始化系统
    if (!store.token) {
      this.$router.push({ path: '/login' })
      return
    }

    // 设置字体大小
    let fontSize = parseInt(localStorage.getItem('fontSize'))
    this.fontSize(fontSize)

    userInfo({}).then((data) => {
      store.setUserInfo(data.result)
      this.status++
    })
    this.status++
    myMerchant({}).then((data) => {
      this.merchantList = data.result
      if (this.merchantList && this.merchantList.length > 0) {
        let storedMerchantOpenId = localStorage.getItem('hudson.community.selectMerchantOpenId')
          if (storedMerchantOpenId) {
            for (var item of this.merchantList) {
              if (storedMerchantOpenId === item.merchantOpenId) {
                this.merchantOpenId = storedMerchantOpenId
                store.setMerchantInfo(item)
                // console.log(item)
                merchantInfoDetail({merchantOpenId: this.merchantOpenId}).then((data) => {
                  this.currentMerchantInfo = data.result
                  this.status++
                })
                this.status++
                return
              }
            }
          }
        this.merchantOpenId = this.merchantList[0].merchantOpenId
        store.setMerchantInfo(this.merchantList[0])
        merchantInfoDetail({merchantOpenId: this.merchantOpenId}).then((data) => {
          this.currentMerchantInfo = data.result
          this.status++
        })
        this.status++
      } else {
        this.$message({ message: '未开通任何权限，请联系管理员处理！', type: 'error' })
      }
    })
  },
  mounted () {
    this.$router.afterEach((to, from) => {
      this.menuActive = to.path
      if (this.$refs && this.$refs.leftMenu) {
        for (let item of to.matched) {
          if (this.$refs.leftMenu.submenus[item.path]) this.$refs.leftMenu.open(item.path)
        }
      }
    })

    // 检测用户是否使用，超时就退出登录
    this.userActivateInterval = window.setInterval(() => {
      if (new Date().getTime() - this.lastActivateTime.getTime() > 3600 * 1000) {
        this.$notify({
          title: '警告',
          message: '由于长时间未操作，系统即将退出登录...',
          duration: 10000,
          type: 'warning',
          onClick: (e) => {
            this.lastActivateTime = new Date()
          }
        })
        setTimeout(() => {
          if (new Date().getTime() - this.lastActivateTime.getTime() > 3600 * 1000) {
            logout().then(() => {
            }).finally(() => {
              store.setToken('')
              this.$router.push('/login')
              this.$notify({
                title: '警告',
                message: '由于长时间未操作，系统已自动退出登录.',
                duration: 0,
                type: 'warning'
              })
            })
          }
        }, 10000)
      }
    }, 12000)

    window.addEventListener('beforeunload', e => this.beforeunloadHandler(e))
    window.addEventListener('unload', e => this.unloadHandler(e))
  },
  destroyed () {
    window.clearInterval(this.userActivateInterval)
    window.removeEventListener('beforeunload', e => this.beforeunloadHandler(e))
    window.removeEventListener('unload', e => this.unloadHandler(e))
  }
}
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "src/style/variables";

.container {
  position: absolute;
  top: 0px;
  bottom: 0px;
  width: 100%;
  background-color: lighten(#0c3d73, 3%);

  .topbar-wrap {
    height: 70px;
    background: rgba(255, 255, 255, .1);
    padding: 0px;
    display: flex;

    .topbar-btn {
      color: #fff;
    }
    /*.topbar-btn:hover {*/
    /*background-color: #4A5064;*/
    /*}*/
    .topbar-logo {
      height: 100%;
      a {
        height: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
      }
      img {
        display: block;
      }
      span {
        display: block;
        color: #fff;
        font-size: 24px;
        line-height: 50px;
        padding: 0 10px;
      }
    }
    .topbar-nav {
      flex: 1;
      .el-menu {
        height: 100%;
        box-sizing: border-box;
        border: 0;
        background-color: transparent;

        /deep/ .el-menu-item {
          height: 100%;
          box-sizing: border-box;
          font-size: 18px;
          font-weight: 800;
          line-height: 70px;
          padding: 0 10px;
          color: #fff;
        }
        /deep/ .el-menu-item:hover, /deep/ .el-menu-item:focus{
          background-color: rgba(255, 255, 255, .2);
        }
      }
    }
    .topbar-account {
      float: right;
      padding-right: 12px;
    }
    .userinfo-inner {
      cursor: pointer;
      color: #fff;
      padding-left: 10px;
      font-size: 18px;
      line-height: 70px;
    }
  }
  .main {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    position: absolute;
    top: 70px;
    bottom: 0px;
    overflow: hidden;
  }

  .content-container {
    // background: lighten(#0c3d73, 70%);
    flex: 1;
    overflow-y: auto;
    // padding: 10px;
    // padding-bottom: 1px;
    position: relative;
  }
}
</style>
